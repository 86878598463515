import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faBoxOpen, faComment, faEnvelope, faFishingRod } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { usePhishingSimulationsWithPackages } from '@/api/phishing-simulations/get'
import { languages } from '@/assets/locales/languages'
import Table, { TalbeColumn } from '@/common/components/Table'
import LibraryTitleCellComponent from '@/common/components/Table/CellComponents/LibraryTitleCellComponent'
import Tags from '@/common/components/Tags/Tags'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'
import useQueryParams from '@/common/hooks/useQueryParams'
import { useAuth } from '@/context/Auth'
import { RowDataItem, SortOrder } from '@/types/common'
import { PhishingSimulation, PhishingSimulationPackage } from '@/types/phishingSimulations'
import { ContentLibraryContext } from '../ContentLibrary'
import SimulationLibraryActionsCellComponent from '@/common/components/Table/CellComponents/SimulationLibraryActionsCellComponent'

type SimulationLibraryTableProps = {
  deleteModule: (simulation: PhishingSimulation) => void
  launchModule: (simulation: PhishingSimulation) => void
  saveModule: (id: string) => void
  saveSimulationPackageToggle: (id: string) => void
  editSimulationPackage: (simulationPackage: PhishingSimulationPackage) => void
  savedSimulations: string[]
  savedPackages: string[]
  queryFilters?: {
    name?: string
    languages?: string[]
    subtitles?: string[]
    tags?: string[]
    vectors?: string[]
    difficulties?: string[]
    ai_generated?: boolean
    package_only?: boolean
    custom?: boolean
  }
}

const initialQueryFilters: any = {
  search: '',
  languages: [],
  tags: [],
  sort_by: 'name',
  sort_order: SortOrder.desc,
  skip: 0,
  limit: 10,
}
const SimulationLibraryTable: FC<SimulationLibraryTableProps> = ({
  deleteModule,
  saveModule,
  savedSimulations = [],
  saveSimulationPackageToggle,
  editSimulationPackage,
  savedPackages = [],
  queryFilters,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigateTo = useNavigateWithLayoutBlocker()
  const { isMultiSimulationSideMenuOpen } = useContext(ContentLibraryContext)
  const { queryParams, updateQueryParam } = useQueryParams(initialQueryFilters)
  const { data: simulationsData, isPending } = usePhishingSimulationsWithPackages({ query: queryParams })
  const isPackageRow = (row: RowDataItem) => row.simulations?.length > 1

  useEffect(() => {
    updateQueryParam({
      search: queryFilters?.name,
      languages: queryFilters?.languages,
      vectors: queryFilters?.vectors,
      tags: queryFilters?.tags,
      difficulties: queryFilters?.difficulties,
      ai_generated: queryFilters?.ai_generated,
      package_only: queryFilters?.package_only,
      // is_public: !queryFilters?.custom, // 'Custome' filter based on 'is_public' invert loginic fails when combined with other filters
    })
  }, [queryFilters, savedPackages])

  const tableColumns: TalbeColumn[] = [
    {
      id: 'name',
      label: t('simulationLibrary.table.title'),
      sortable: true,
      component: (row) => {
        const isOrganizationOwned = row.organization_id === user?.current_client_id
        return (
          <LibraryTitleCellComponent
            title={row.name}
            isCustom={isOrganizationOwned && !row.ai_generated && !row.is_public}
            isAiGenerated={isOrganizationOwned && row.ai_generated && !row.is_public}
          />
        )
      },
    },
    {
      id: 'languages',
      label: t('simulationLibrary.table.languages'),
      component: (row) =>
        !!row.languages?.message && (
          <Tags
            justify="center"
            useChips={false}
            tags={(row.languages.message ?? []).map((language: string) => ({
              label: languages[language]?.name ?? language,
            }))}
            limit={1}
          />
        ),
    },
    {
      id: 'difficulties',
      sortable: true,
      label: t('simulationLibrary.table.difficulty'),
      format: (row) => row.difficulties?.join(', '),
    },
    {
      id: 'tags',
      label: t('simulationLibrary.table.topics'),
      component: (row) => (
        <Tags
          justify="center"
          useChips={false}
          tags={(row.tags ?? []).map((tag: string) => ({
            label: tag,
          }))}
          limit={1}
        />
      ),
    },
    {
      id: 'simulations',
      label: t('simulationLibrary.table.type'),
      component: (row) => {
        return isPackageRow(row) ? (
          <FontAwesomeIcon icon={faBoxOpen as IconProp} fontSize={20} />
        ) : (
          <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={20} />
        )
      },
    },
    {
      id: 'vectors',
      label: t('simulationLibrary.table.vector'),
      component: (row) => (
        <Box style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
          {row.vectors.includes('sms') && <FontAwesomeIcon icon={faComment as IconProp} fontSize={20} />}
          {row.vectors.includes('email') && <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={20} />}
          {row.vectors.includes('whatsapp') && <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={20} />}
        </Box>
      ),
    },
    {
      id: 'actions',
      label: '',
      component: (row) => {
        const isPackage = isPackageRow(row)
        return (
          <SimulationLibraryActionsCellComponent
            row={row}
            isPackage={isPackage}
            saveFunction={isPackage ? saveSimulationPackageToggle : saveModule}
            editSimulationPackage={editSimulationPackage}
            deleteFunction={deleteModule}
            isSaved={isPackage ? savedPackages.includes(row.id) : savedSimulations.includes(row.id)}
            isMultiSimulationSideMenuOpen={!!isMultiSimulationSideMenuOpen}
          />
        )
      },
    },
  ]

  const handleRowClick = (row: RowDataItem) => {
    if (!row.id) return
    !!(row as RowDataItem)?.simulations
      ? navigateTo(`/content-library/simulations-package/${row.id}`)
      : navigateTo(`/content-library/simulations/${row.id}`)
  }
  return (
    <Table
      loading={isPending}
      data={simulationsData?.results as RowDataItem[]}
      total={simulationsData?.total || 0}
      queryParams={queryParams}
      updateQueryParam={updateQueryParam}
      columns={tableColumns}
      rowClick={handleRowClick}
      draggabledSimulationRows={!!isMultiSimulationSideMenuOpen}
    />
  )
}

export default SimulationLibraryTable
