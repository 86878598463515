import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, styled } from '@mui/material'
import MuiSelect, { SelectProps as MuiSelectProps, SelectChangeEvent } from '@mui/material/Select'
import { FieldHookConfig, useField } from 'formik'
import { FC, ReactNode } from 'react'

import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HelperTextComponent from '../HelperTextComponent'

interface SelectProps
  extends Omit<MuiSelectProps, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  disableFormik?: boolean
  value?: MuiSelectProps['value']
  options: { value: string; label: string | ReactNode }[]
  className?: string
  onChange?: MuiSelectProps['onChange']
  requiredLabel?: boolean
}

export const MuiSelectSX = {}

const Select: FC<SelectProps> = ({
  name,
  label,
  placeholder,
  // selectProps,
  disableFormik = false,
  className,
  options = [],
  onChange,
  requiredLabel, // not part of otherProps - because it's only fot the label.
  ...otherProps
}) => {
  const [field, meta] = useField(name)

  const configSelect: MuiSelectProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
  }

  if (!disableFormik) {
    // Merge Formik-related props if Formik is enabled
    Object.assign(configSelect, {
      ...field,
      // className: [disableFormik ? '' : FieldClasses.validate, className].join(' '),
      error: meta.touched && Boolean(meta.error),
      onChange: onChange
        ? (event: SelectChangeEvent, child: ReactNode) => {
            field.onChange(event)
            onChange(event, child)
          }
        : field.onChange,
    })
  } else {
    // Attach provided onChange handlers for non-Formik usage
    if (onChange) configSelect.onChange = onChange
  }
  return (
    <StyledFormControl>
      <InputLabel id={`select-${name}`} required={requiredLabel}>
        {label}
      </InputLabel>
      <MuiSelect
        IconComponent={(props) => (
          <Box {...props} padding={1} marginTop={-1.25}>
            <FontAwesomeIcon icon={faAngleRight} fontSize={18} />
          </Box>
        )}
        labelId={`select-${name}`}
        className={className}
        input={<StyledOutlinedInput label={label} />}
        {...configSelect}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {meta.touched && meta.error && <HelperTextComponent hasError={true} helperText={meta.error} />}
    </StyledFormControl>
  )
}

export const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  '& MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  fieldset: {
    borderRadius: '10px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.5px',
  },
  '&:hover fieldset': {
    borderWidth: '0.5px',
  },
  '& legend': {
    fontSize: '10px',
    margin: '0 6px',
  },
}))

export const StyledFormControl = styled(FormControl)(() => ({
  position: 'relative',
  display: 'flex',
  label: {
    fontSize: '12px',
    padding: '2px 8px',
    margin: '1px 0 0',
  },
  '.MuiInputBase-root': {
    display: 'flex',
    '.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    padding: 0,
    margin: 0,
    fontSize: '16px',
  },
}))
export default Select
