import { theme } from '@/theme/theme'
import { Tab, styled } from '@mui/material'

const MainTab = styled(Tab)(() => ({
  padding: '8px 16px',
  background: theme.palette.grey[300],
  borderLeft: `solid ${theme.palette.grey[400]} 1px`,
  borderRight: `solid ${theme.palette.grey[400]} 1px`,
  marginTop: '2px',
  color: theme.palette.blueGray[900],
  fontSize: 16,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '28px',
  textTransform: 'none',
  maxWidth: '262px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'block',
  '.MuiTab-iconWrapper': {
    display: 'inline',
  },
  '&:hover': {
    maxWidth: 'none',
    background: theme.palette.grey[400],
  },
  '&:active': {
    maxWidth: 'none',
    background: theme.palette.grey[500],
    borderColor: theme.palette.grey[500],
  },
  '&.Mui-selected': {
    marginTop: '0px',
    boxShadow: 'none',
    background: theme.palette.white,
    border: `solid ${theme.palette.cyan[500]} 2px`,
    borderRight: '0px',
    borderLeft: '0px',
    borderBottom: '0px',
    color: theme.palette.blueGray[900],
    fontSize: 18,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&:hover': {
      background: theme.palette.blueDianne[50],
    },
    '&:active': {
      background: theme.palette.blueDianne[100],
    },
  },
}))

export default MainTab
