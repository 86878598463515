import { useChangePassword } from '@/api/profile/edit-change-password'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { CurrentUser } from '@/api/profile/current-user'
import { getErrorMessage } from '@/api/utils/get-error'
import { ColunmsItem, FormButtons, FormSection, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import PasswordField from '@/common/components/Inputs/PasswordField'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'

const AdminProfileSecuritySection: FC<{ user: CurrentUser | undefined }> = ({ user }) => {
  const { successToast, errorToast } = useToast()
  const { t } = useTranslation()
  const [changePassword] = useChangePassword()

  const changePasswordValidationSchema = Yup.object().shape({
    current_password: Yup.string().test(
      'current-password-check',
      'Current password is required',
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password, confirm_new_password } = this.parent
        return new_password || confirm_new_password ? !!value && value?.length >= 8 : true
      }
    ),
    confirm_new_password: Yup.string().test(
      'confirm-new-password-check',
      "Passwords don't match",
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password } = this.parent
        if (!new_password && !value) return true
        return value === new_password || this.createError({ message: "Passwords don't match" })
      }
    ),
  })

  const handleSubmitChangePassword = async (values: { current_password: string; new_password: string }) => {
    try {
      await changePassword(values)
      successToast(t(`settings.admins.profile.passwordSuccessToast`))
      //resetForm()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t(`settings.admins.profile.passwordErrorToast`))
    }
  }

  return (
    <Formik
      onSubmit={handleSubmitChangePassword}
      initialValues={{
        current_password: '',
        new_password: '',
        confirm_new_password: '',
      }}
      validationSchema={changePasswordValidationSchema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <TwoColunmsLayout>
            <ColunmsItem>
              <FormSection>{t(`settings.admins.profile.resetPassword`)}</FormSection>
              <Box display="flex" flexDirection="column" gap={4}>
                <PasswordField
                  label={t('settings.configurationTab.resetPassword.currentPassword')}
                  name="current_password"
                />
                <PasswordField
                  label={t('settings.configurationTab.resetPassword.newPassword')}
                  name="new_password"
                  withStrengthIndicator
                />
              </Box>
            </ColunmsItem>

            <ColunmsItem>
              <PasswordField
                style={{ marginTop: '145px' }}
                label={t('settings.configurationTab.resetPassword.confirmNewPassword')}
                name="confirm_new_password"
              />
            </ColunmsItem>
            <ColunmsItem offsetLeft>
              <FormButtons>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained" disabled={!dirty || !isValid}>
                  {t(`settings.admins.profile.savePassword`)}
                </LoadingButton>
              </FormButtons>
            </ColunmsItem>
          </TwoColunmsLayout>
        </Form>
      )}
    </Formik>
  )
}

export default AdminProfileSecuritySection
