import React, { useState, useMemo, ReactElement } from 'react'
import PaginatingTable from '../../common/components/Tables/PaginatingTable'
import DateFilterV2 from '../../common/components/Tables/DateFilterV2'
import HumanizedScore from '../../common/components/HumanizedScore/HumanizedScore'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Paper, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useMemberCampaigns, MemberParams, Campaign } from '@/api/member/get-campaigns'
import { faClapperboardPlay, faBlockQuestion, faFishingRod } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export enum ASSET_TYPE {
  phishing_simulation = 'phishing_simulation',
  training_video = 'training_video',
}

type UserCampaignsTableProps = {
  userId: string
}

type PageVariables = {
  pageSize: number
  pageIndex: number
}

const INITIAL_QUERY_FILTERS = {
  skip: 0,
  limit: 10,
  sort_by: 'start_date',
  sort_order: 'asc',
  start_date: '',
  end_date: dayjs().format('YYYY-MM-DD'),
}

const getIconsForCampaign = (data: Campaign): ReactElement[] => {
  const icons: ReactElement[] = []

  if (data.has_quiz) {
    icons.push(
      <div
        style={{
          padding: '0  10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <FontAwesomeIcon icon={faBlockQuestion as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Quiz
        </Typography>
      </div>
    )
  }
  if (data.asset_type === ASSET_TYPE.phishing_simulation) {
    icons.push(
      <div
        style={{
          padding: '0  10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Simulation
        </Typography>
      </div>
    )
  } else if (data.asset_type === ASSET_TYPE.training_video) {
    icons.push(
      <div
        style={{
          padding: '0 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Video
        </Typography>
      </div>
    )
  }

  return icons
}

export default function UserCampaignsTable({ userId }: UserCampaignsTableProps) {
  const classes = useStyles()
  const [pageCount, setPageCount] = useState(0)
  const navigateTo = useNavigate()
  const queryClient = useQueryClient()
  const [queryFilters, setQueryFilters] = useState<MemberParams>(INITIAL_QUERY_FILTERS)

  const {
    data: { total, results },
    isLoading,
  } = useMemberCampaigns({
    id: userId,
    queryParams: queryFilters,
  })
  const { t } = useTranslation()
  const theme = useTheme()

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('userProfile.campaignsTable.campaign'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('userProfile.campaignsTable.date'),
        accessor: 'start_date',
        Cell: ({ value }: { value: string }) => dayjs(value).format('MMM DD, YYYY'),
      },
      {
        Header: t('userProfile.campaignsTable.type'),
        accessor: 'asset_type',
        Cell: ({ row }) => {
          const data = row.original
          return (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {getIconsForCampaign(data)}
            </div>
          )
        },
      },
      {
        Header: t('userProfile.campaignsTable.score'),
        accessor: 'score',
        Cell: ({ value }: { value: number }) => <HumanizedScore score={value} />,
      },
      {
        Header: t('userProfile.campaignsTable.status'),
        accessor: 'status',
        Cell: ({ value }: { value: string }) => {
          const getColor = () => {
            switch (value) {
              case 'scheduled':
                return theme.palette.text.primary
              case 'executed':
                return theme.palette.success.main
              case 'canceled':
                return theme.palette.error.main
              case 'completed':
                return theme.palette.success.main
              default:
                return theme.palette.text.primary
            }
          }
          const color = getColor()
          return (
            <Typography variant={'subtitle2'} style={{ color }}>
              {t(`userProfile.campaignsTable.${value}`)}
            </Typography>
          )
        },
      },
    ],
    [t, theme.palette.error.main, theme.palette.success.main, theme.palette.text.primary]
  )

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }: PageVariables) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        skip: prevState.limit * pageIndex,
        limit: pageSize,
      }))
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(total / pageSize))
      queryClient.prefetchQuery({
        queryKey: [
          'members',
          'campaigns',
          userId,
          {
            ...queryFilters,
            skip: pageSize * (pageIndex + 1),
            limit: pageSize,
          },
        ],
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [total]
  )

  function onRowClick(row) {
    const {
      original: { _id, asset_type },
    } = row
    if (asset_type === 'phishing_simulation') {
      navigateTo(`/campaign/${_id}`)
    } else {
      navigateTo(`/training-campaigns/${_id}`)
    }
  }

  return (
    <>
      <DateFilterV2
        queryFilters={queryFilters}
        setQueryFilters={setQueryFilters}
        label={`${t('userProfile.attackCampaigns')}`}
      />
      <Paper className={classes.table}>
        <PaginatingTable
          noResultsTextId={'userProfile.campaignsTable.noCampaigns'}
          columns={columns}
          data={results}
          fetchData={fetchData}
          loading={isLoading}
          pageCount={pageCount}
          count={total}
          onRowClick={onRowClick}
          enableCheckbox={false}
        />
      </Paper>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
  })
)
