import { ALL_DATA_LIMIT } from '@/api/constants'
import { useAddMembersToGroup } from '@/api/group/add-members'
import { useEditGroup } from '@/api/group/edit'
import { useMembers } from '@/api/members/get'
import { useOffices } from '@/api/offices/get'
import Form from '@/common/components/Form/Form'
import useForm, { Field } from '@/common/components/Form/useForm'
import useToast from '@/common/hooks/useToast'
import { Group } from '@/types/groups'
import { MembersListQuery } from '@/types/members'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'

type CreateGroupModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
  group?: Group
  mode: 'group' | 'users'
}

type SelectedUser = {
  _id: string
  label: string
}

// need to replace this Modal with our new Modal (theme)
export default function EditGroupModal({ open, setOpen, group, onClose = () => {}, mode }: CreateGroupModalProps) {
  const { t } = useTranslation()
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const [newUsers, setNewUsers] = useState<SelectedUser[]>([])
  const [newManagers, setNewManagers] = useState<SelectedUser[]>([])
  const [search, setSearch] = useState('')
  const { mutateAsync: editGroup } = useEditGroup()
  const { mutateAsync: addMembersToGroup } = useAddMembersToGroup()
  const { successToast, errorToast } = useToast()

  const { data: members } = useMembers({
    limit: 50,
    search: search,
    groups: group && mode === 'group' ? [group._id] : [],
  } as MembersListQuery)

  const usersList = useMemo(() => {
    return members
      ? members.results.map((member) => ({
          label: `${member.first_name} ${member.last_name}`,
          _id: member._id,
        }))
      : []
  }, [members])

  const optionalManagers = useMemo(() => {
    const managersIds = newManagers.map((manager) => manager._id)
    return usersList.filter((user) => !managersIds.includes(user._id))
  }, [newManagers, usersList])

  const managersList = useMemo(
    () =>
      (group?.managers || []).map(({ first_name, last_name, _id }) => ({
        label: `${first_name} ${last_name}`,
        _id: _id,
      })),
    [group]
  )

  useEffect(() => {
    setNewManagers(managersList)
  }, [managersList])

  function handleChange(event, newValue) {
    setNewUsers(newValue)
  }

  function handleChangeManagers(event, newValue) {
    setNewManagers(newValue)
  }

  function handleQueryChange(event, newInputValue) {
    setSearch(newInputValue)
  }

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'name',
        initialValue: group?.name ?? '',
        label: 'createGroupModal.name',
        validationRule: string().required('createGroupModal.errors.missingName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
      {
        name: 'offices',
        initialValue: group?.offices ?? [],
        label: 'createUserModal.office',
        validationRule: mode === 'users' ? undefined : string().required('createUserModal.errors.missingOffice'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
          SelectProps: {
            multiple: (offices || []).length > 1,
          },
        },
        options: offices
          ? offices.map((office) => ({
              label: office.name,
              value: office._id,
            }))
          : [],
      },
    ],
    [group, offices, mode]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    setNewUsers([])
    setNewManagers(managersList)
    clearForm()
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  })

  async function handleSaveEdit({ name, offices }) {
    if (group) {
      try {
        const users = newUsers.map((user) => user._id)
        await editGroup({
          _id: group._id,
          name,
          offices: offices || [],
          managers: newManagers.map((user) => user._id) || [],
        })
        if (users.length > 0) {
          await addMembersToGroup({ users, _id: group._id })
        }
        successToast(t('groupProfile.groupEdited'))
        handleClose()
      } catch (e) {
        const errorMessage = e.response?.data?.message ?? t('groupProfile.errors.faliedEditGroup')
        console.log('error', e)
        errorToast(errorMessage)
      }
    }
  }

  function handleSumbit(values) {
    const { name, offices } = values
    handleSaveEdit({ name, offices })
  }

  return (
    <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="group-dialog-title">
      <DialogTitle id="group-dialog-title">
        {mode === 'group' ? t('groupProfile.groupModal.editGroup') : t('groupProfile.groupModal.addUsers')}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        {mode === 'group' ? (
          <>
            <Form {...formProps} />
            <div style={{ marginTop: 16 }}>
              <Autocomplete
                value={newManagers}
                onChange={handleChangeManagers}
                onInputChange={handleQueryChange}
                multiple
                id="tags-filled"
                options={optionalManagers}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('createGroupModal.noAdditionalUsers')}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return <Chip label={option.label} {...getTagProps({ index })} />
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('createGroupModal.groupManagers')}
                    placeholder={t('createGroupModal.searchUsers')}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <Autocomplete
            value={newUsers}
            onChange={handleChange}
            onInputChange={handleQueryChange}
            multiple
            id="tags-filled"
            options={usersList.map((option) => option)}
            getOptionLabel={(option) => option.label}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option.label} {...getTagProps({ index })} />
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('groupProfile.groupModal.newUsers')}
                placeholder={t('groupProfile.groupModal.searchUsers')}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t('groupProfile.groupModal.cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {t('groupProfile.groupModal.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
