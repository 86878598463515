import { MembersListQuery } from '@/types/members'
import { Box, Paper, styled } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { useMembers } from '@/api/members/get'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import SimpleSearchBar from '@/common/components/SimpleSearchBar/SimpleSearchBar'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import { theme } from '@/theme/theme'
import { Group } from '@/types/groups'
import dayjs from 'dayjs'

type OfficeUsersTableProps = {
  groups?: Group[]
  currentOfficeId: string
}

// Need to be replaced with the new table!!
const OfficeUsersTable = ({ currentOfficeId }: OfficeUsersTableProps) => {
  const initalQueryFilter = { offices: [currentOfficeId], skip: 0, limit: 5 }
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const [queryFilters, setQueryFilters] = useState(initalQueryFilter)
  const { data: members, isLoading, isFetching } = useMembers(queryFilters as MembersListQuery)
  const [pageCount, setPageCount] = useState(0)

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        skip: prevState.limit * pageIndex,
        limit: pageSize,
      }))
      setPageCount(Math.ceil((members?.total || 0) / pageSize))
    },
    [members?.total]
  )

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: t('users.usersTable.email'),
        accessor: 'username',
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
      // not exist for new members:
      {
        Header: t('users.usersTable.lastCampaign'),
        accessor: 'last_campaign',
        Cell: ({ value }) => (value ? dayjs.unix(value).format('DD/MM/YY') : ''),
      },
      {
        Header: t('users.usersTable.status'),
        accessor: 'state',
        Cell: ({ value }) => (value == 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive')),
      },
    ],
    [t]
  )

  function handleNavigateToUserProfile({ original }) {
    navigateTo(`/recipients/users/${original._id}`)
  }
  const handleSearch = (value: string) => {
    setQueryFilters((prevFilters) => ({
      ...prevFilters,
      search: value,
    }))
  }
  return !isLoading ? (
    <Paper>
      {/* // Need to be replaced with the new table! */}
      <StyledSearchBox>
        <SimpleSearchBar onChange={handleSearch} debounceTime={0} />
      </StyledSearchBox>
      <PaginatingTable
        enableCheckbox={false}
        noResultsTextId={'users.usersTable.noResults'}
        columns={columns}
        data={members?.results ?? []}
        fetchData={fetchData}
        loading={isLoading || isFetching}
        pageCount={pageCount}
        count={members?.total}
        onRowClick={handleNavigateToUserProfile}
      />
    </Paper>
  ) : (
    <LoadingContainer />
  )
}

const StyledSearchBox = styled(Box)(() => ({
  padding: theme.spacing(1),
}))
export default OfficeUsersTable
