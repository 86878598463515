import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { API_URL } from '@/api/constants'

type RoleParams = {
  _id: string
  role: string
  receive_plugin_reports: boolean
  receive_campaign_reports: boolean
}

const editAdminRole = async (props: RoleParams) => {
  const url = `${API_URL}/admins/${props._id}`
  const { data } = await axios.put(url, props, { withCredentials: true })

  return data
}

export const useEditAdminRole = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: editAdminRole,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
