import { PagedQuery } from './common'

//TODO Verify this enum
export enum MemberState {
  active = 'active',
  inactive = 'inactive',
}

export type MemberRequest = {}

export type NewMemberParams = {
  username: string
  first_name: string
  last_name: string
  phone_number?: string
  office_id: string
  member_of: string[]
}

export type EditMembersParams = { _id: string; params: Partial<NewMemberParams> }

export type Member = {
  _id: string
  first_name: string
  last_name: string
  username: string
  office_id: string
  member_of: string[]
  awareness_score: number
  phone_number: string
  office: {}
  state: MemberState
  domain_verified: boolean
}

export type MemberListItem = Member & {}

export enum MembersQuerySort {
  first_name = 'first_name',
  username = 'username',
  phone_number = 'phone_number',
  groups = 'groups',
  awareness_score = 'awareness_score',
  status = 'status',
}

export type MembersListQuery = PagedQuery<MembersQuerySort> & {
  search: string
  status: MemberState[]
  timezone: string[]
  language: string[]
  groups?: string[]
  offices?: string[]
}

// Bulk upload
export enum UploadMemberConflictResolutionStrategy {
  keep_existing = 'keep_existing',
  overwrite_existing = 'overwrite_existing',
  custom = 'custom',
}

export type BulkUploadConflictMember = {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  office: string
  groups: string[]
}

export type BulkUploadConflict = {
  row: number
  incomming: BulkUploadConflictMember
  existing: BulkUploadConflictMember
}
