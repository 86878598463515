import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ServerError } from '../types'
import { API_URL } from '../constants'

type CreateMembersGroupsRequest = {
  ids: string[]
  groups: string[]
}

type CreateMembersGroupsResponse = {
  message: string
}

const createMembersGroups = async (values: CreateMembersGroupsRequest) => {
  const url = `${API_URL}/members/groups/`

  if (typeof values.groups === 'string') {
    values.groups = [values.groups]
  }

  const { data } = await axios.put<CreateMembersGroupsResponse>(url, values, {
    withCredentials: true,
  })

  return data
}

const useCreateMembersGroups = () => {
  const queryClient = useQueryClient()
  return useMutation<CreateMembersGroupsResponse, ServerError, CreateMembersGroupsRequest>({
    mutationFn: createMembersGroups,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] })
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

export default useCreateMembersGroups
