import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { Group } from '@/types/groups'
import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'

//need to be replace with new-enpoint!
const getGroup = async (_id: string) => {
  const url = `${API_URL}/groups/${_id}`
  const { data } = await axios.get(url, { withCredentials: true, paramsSerializer: paramsSerializer })

  return data.data
}
export const useGroup = (_id: string) => {
  return useQuery<Group>({
    queryKey: ['groups', _id],
    queryFn: () => {
      return getGroup(_id)
    },
    placeholderData: keepPreviousData,
  })
}
