import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { Office } from '@/types/offices'

//need to be replace with new-enpoint!
const getOffice = async (_id: string) => {
  const url = `${API_URL}/offices/${_id}`
  const { data } = await axios.get(url, { withCredentials: true })
  return data.data
}

export const useGetOffice = (_id: string) => {
  return useQuery<Office>({
    queryKey: ['offices', _id],
    queryFn: () => {
      return getOffice(_id)
    },
    placeholderData: keepPreviousData,
  })
}
