import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OpenAIRequest } from '@/api/open-ai/openai-request'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import { PhishingSimulationVector } from '@/types/phishingSimulations'
import AppEmailForm from './AiForms/AppEmailForm'
import { HumanEmailForm } from './AiForms/HumanEmailForm'
import AiLoader from './components/AiLoader'
import EmailBodyOutput from './components/EmailBodyOutput'
import EmailPlaceholder from './components/EmailPlaceholder'
import MonitorPreview from './components/MonitorPreview'
import VectorToggleButtons from './components/VectorToggleButtons'
import { loadingPhrases } from './constants/loading-phrases'
import { SIMULATION_TYPES, useAiSimulationContext } from './state'
import { createEmailBodyPrompt } from './utils/createEmailBodyPrompt'
import { createEmailSubjectPrompt } from './utils/createEmailSubjectPrompt'
import { createSMSBodyPrompt } from './utils/createSMSBodyPrompt'
import { createSMSSubjectPrompt } from './utils/createSMSSubjectPrompt'
import { createWhatsAppBodyPrompt } from './utils/createWhatsAppBodyPrompt'
import MobilePhonePreview from '@/common/components/MobilePhonePreview'
import WhatsAppPreview from '@/common/components/MobilePhonePreview/WhatsAppPreview'
import SmsAppPreview from '@/common/components/MobilePhonePreview/SmsAppPreview'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import { theme } from '@/theme/theme'

const LOAD_TIME = 10000

// TODO: consider change name of this component which used for sms and whatsapp simulations.
const EmailBuilder: FC = () => {
  const {
    currentStep,
    setCurrentStep,
    simulationType,
    setSimulationType,
    language,
    toneOfVoice,
    emailBody,
    senderName,
    senderPosition,
    instructions,
    vectorType,
    subjectOutput,
    setSubjectOutput,
    bodyOutput,
    setBodyOutput,
    designedEmail,
    appName,
    emailSenderSignature,
    customLogo,
    resetEmail,
    personalizedTemplate,
    findingsInstructions,
    iconTheme,
  } = useAiSimulationContext()

  const theme = useTheme()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [subjectLoading, setSubjectLoading] = useState(false)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>()
  const [randomPhrase, setRandomPhrase] = useState('')
  const [isGenerateClicked, setIsGenerateClicked] = useState(false)
  const [isPreviewInFullscreen, setIsPreviewInFullscreen] = useState(false)

  useEffect(() => {
    updateRandomPhrase(loading)
  }, [loading])

  const updateRandomPhrase = (loading: boolean) => {
    if (loading) {
      setRandomPhrase(getRandomPhrase())

      const id = setInterval(() => {
        setRandomPhrase(getRandomPhrase())
      }, LOAD_TIME)

      setIntervalId(id)
    } else {
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    setBodyOutput('')
    setSubjectOutput('')
    setIsGenerateClicked(false)
  }, [vectorType])

  const getRandomPhrase = () => {
    const randomIndex = Math.floor(Math.random() * loadingPhrases.length)
    return loadingPhrases[randomIndex]
  }

  const refreshEmailSubject = async () => {
    setSubjectLoading(true)

    handleGenerateSubject().then(async (response) => {
      const message = await response.json()
      const data = message?.choices[0]?.message?.content
      setSubjectOutput(data)
      setSubjectLoading(false)
    })
  }

  const createMessage = async () => {
    setLoading(true)
    // create email
    const response = await handleGenerateBody()
    const message = await response.json()
    const data = message?.choices[0]?.message?.content
    // add findings - second iteration
    const response2 =
      vectorType === PhishingSimulationVector.SMS
        ? await handleGenerateFindingsForSMS(data, findingsInstructions)
        : vectorType === PhishingSimulationVector.WhatsApp
        ? await handleGenerateFindingsForWhatsapp(data, findingsInstructions)
        : await handleGenerateFindings(data, findingsInstructions)
    const message2 = await response2.json()
    const data2 = message2?.choices[0]?.message?.content
    setBodyOutput(data2)
    setLoading(false)
    if (vectorType === PhishingSimulationVector.Email)
      handleGenerateSubject().then(async (response) => {
        const message = await response.json()
        const data = message?.choices[0]?.message?.content
        setSubjectOutput(data)
      })
  }

  const handleContinue = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleGenerateFindings = async (emailBody: string, findingsInstructions: string) => {
    const prompt = `
      Act as html expert. Given this Email body: ${emailBody}.
      Adjust the given email body, to include: ${findingsInstructions}.
      Guessing the language of the email body set the dir attribute in the html to be either RTL or LTR.
      Return html structured for email only, (no backticks, no borders, quotes).
    `

    try {
      const stream = await OpenAIRequest({ prompt, maxTokens: 2000 })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const handleGenerateFindingsForSMS = async (emailBody: string, findingsInstructions: string) => {
    const prompt = `
      Act as html expert. Given this SMS body: ${emailBody}.
      Adjust the given SMS body, to include: ${findingsInstructions}.
      Return html structured for SMS only, (no backticks, no borders, quotes).
    `

    try {
      const stream = await OpenAIRequest({ prompt, maxTokens: 2000 })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const handleGenerateFindingsForWhatsapp = async (messageBody: string, findingsInstructions: string) => {
    const prompt = `
      Act as html expert. Given this Whatsapp body: ${messageBody}.
      Adjust the given Whatsapp body, to include: ${findingsInstructions}.
      Return html structured for Whatsapp only, (no backticks, no borders, quotes).
    `

    try {
      const stream = await OpenAIRequest({ prompt, maxTokens: 2000 })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const handleGenerateBody = async () => {
    // TODO: split this into different prompt based on the simulation type etc.
    const prompt = createEmailBodyPrompt(
      simulationType,
      language,
      toneOfVoice,
      emailBody,
      instructions,
      senderName,
      senderPosition,
      vectorType,
      designedEmail,
      appName,
      emailSenderSignature,
      customLogo,
      iconTheme,
      personalizedTemplate
    )
    const smsPrompt = createSMSBodyPrompt(
      simulationType,
      language,
      toneOfVoice,
      emailBody,
      instructions,
      senderName,
      senderPosition,
      vectorType,
      designedEmail,
      appName
    )

    const whatsappPrompt = createWhatsAppBodyPrompt(
      simulationType,
      language,
      toneOfVoice,
      emailBody,
      instructions,
      senderName,
      senderPosition,
      vectorType,
      designedEmail,
      appName,
      emailSenderSignature,
      customLogo
    )

    try {
      const stream = await OpenAIRequest({
        prompt:
          vectorType === PhishingSimulationVector.SMS
            ? smsPrompt
            : vectorType === PhishingSimulationVector.WhatsApp
            ? whatsappPrompt
            : prompt,
        maxTokens: designedEmail ? 1000 : 600,
      })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const handleGenerateSubject = async () => {
    const emailPrompt = createEmailSubjectPrompt(language, toneOfVoice, emailBody)
    const smsPrompt = createSMSSubjectPrompt(language, toneOfVoice, emailBody)
    const prompt = vectorType !== PhishingSimulationVector.Email ? smsPrompt : emailPrompt
    try {
      const stream = await OpenAIRequest({ prompt })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const enableGenerateButton = () => {
    if (loading) {
      return false
    }
    return simulationType === SIMULATION_TYPES.HUMAN
      ? language && toneOfVoice && emailBody && senderName && senderPosition
      : language && toneOfVoice && emailBody && appName
  }

  const monitorOrPhonePreview = () => {
    const isGenerated = bodyOutput && true
    return vectorType === PhishingSimulationVector.Email ? (
      <Box display="flex" flexDirection="column" alignItems="center">
        <MonitorPreview
          pageType="email"
          isLoading={loading}
          hasContent={!!isGenerated}
          onFullscreenToggle={(isFullscreen) => setIsPreviewInFullscreen(isFullscreen)}
          subject={subjectOutput}
          onSubjectRefresh={refreshEmailSubject}
          subjectLoading={subjectLoading}>
          {bodyOutput ? (
            <EmailBodyOutput text={bodyOutput} isModal={isPreviewInFullscreen} />
          ) : (
            <>
              {loading ? (
                <Box p={1}>
                  <Typography sx={{ textAlign: 'center', color: theme.palette.white }}>{randomPhrase}</Typography>
                  <AiLoader />
                </Box>
              ) : (
                <EmailPlaceholder isModal={isPreviewInFullscreen} />
              )}
            </>
          )}
        </MonitorPreview>
      </Box>
    ) : vectorType === PhishingSimulationVector.SMS ? (
      <Box style={{ width: '250px' }}>
        <MobilePhonePreview isLoading={loading} fullScreenButton>
          <SmsAppPreview sender={appName || senderName} message={bodyOutput} isHookBG={true} />
        </MobilePhonePreview>
      </Box>
    ) : (
      <Box style={{ width: '250px' }}>
        <MobilePhonePreview isLoading={loading} fullScreenButton>
          <WhatsAppPreview sender={appName || senderName} message={bodyOutput} />
        </MobilePhonePreview>
      </Box>
    )
  }

  return (
    <Box width={'100%'}>
      <MainTabsWrapper>
        <MainTabs
          value={simulationType}
          onChange={(_event, newValue) => {
            resetEmail()
            setSimulationType(newValue)
          }}
          indicatorColor="primary"
          textColor="primary">
          <MainTab disabled={loading} label="Impersonate an app" value={SIMULATION_TYPES.APP} />
          <MainTab disabled={loading} label="Impersonate a human" value={SIMULATION_TYPES.HUMAN} />
        </MainTabs>
      </MainTabsWrapper>
      <MainTabPage>
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={3.25}>
          <Box display="flex" flexDirection="column" gap={4} flex={1}>
            <Box>
              <Box>
                <Box display="flex" flexDirection="column" gap={1.5} height="fit-content">
                  <VectorToggleButtons disabled={loading} />
                  {simulationType === SIMULATION_TYPES.HUMAN ? (
                    <HumanEmailForm simulationVectorType={vectorType} />
                  ) : (
                    <AppEmailForm simulationVectorType={vectorType} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Preview */}
          <Box display="flex" flexDirection="column" flex={1} alignItems="center" gap={theme.spacing(4)}>
            {monitorOrPhonePreview()}
            <AiDisclaimer />
          </Box>
        </Box>
        {/* Spacer */}
        <Box mt={2.5}></Box>

        {/* Action Buttons */}
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="50%">
            {!bodyOutput && !isGenerateClicked ? (
              <Button
                disabled={!enableGenerateButton()}
                onClick={(e) => {
                  createMessage()
                  e.stopPropagation()
                }}
                variant={'contained'}>
                {t('simulationLibrary.ai.generate')}
              </Button>
            ) : (
              <Button
                disabled={!enableGenerateButton()}
                onClick={(e) => {
                  setBodyOutput('')
                  setIsGenerateClicked(true)
                  createMessage()
                  e.stopPropagation()
                }}
                variant={'outlined'}>
                {t('simulationLibrary.ai.regenerate')}
              </Button>
            )}
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="50%">
            {bodyOutput && (
              <Box>
                <Button onClick={handleContinue} variant={'contained'}>
                  {t('simulationLibrary.ai.continue')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </MainTabPage>
    </Box>
  )
}

const AiDisclaimer = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center">
      <InfoTooltip
        color={theme.palette.blueDianne[900]}
        title={
          <Box padding={theme.spacing(0.5, 0)} gap={theme.spacing(1)}>
            <Typography fontWeight={theme.typography.fontWeightBold} fontSize={14}>
              {t('simulationLibrary.ai.disclaimerTooltip.title')}
            </Typography>
            <Typography lineHeight="normal" fontSize={14}>
              {t('simulationLibrary.ai.disclaimerTooltip.message')}
            </Typography>
          </Box>
        }
      />
      <Typography fontSize={14} fontWeight={theme.typography.fontWeightMedium}>
        {t('simulationLibrary.ai.disclaimer')}
      </Typography>
    </Box>
  )
}
export default EmailBuilder
