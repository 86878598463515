import axios from 'axios'

import { API_URL } from '../constants'
import { useMutation } from '@tanstack/react-query'

const getCampaignReport = async (id: string): Promise<{ data: Blob; filename: string }> => {
  const url = `${API_URL}/campaigns/${id}/report/`
  const { headers, data } = await axios.get(url, {
    withCredentials: true,
    responseType: 'blob',
  })

  let filename = 'report.pdf'
  const contentDisposition = headers['content-disposition']
  const match = contentDisposition?.match(/filename="([^"]+)"/i)
  if (match && match[1]) {
    filename = match[1]
  }

  return { data, filename }
}

export const useGetCampaignReport = (onError: (error: Error) => void) => {
  return useMutation({
    mutationFn: getCampaignReport,
    onSuccess: async ({ data, filename }) => {
      const decodedFilename = decodeURIComponent(filename)
      const link = document.createElement('a')
      link.href = URL.createObjectURL(data)
      link.download = decodedFilename
      link.click()
      URL.revokeObjectURL(link.href)
    },
    onError: (error) => {
      onError && onError(error)
    },
  })
}
