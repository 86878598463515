import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation, faSquareExclamation, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Box, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ClientDetailsResult, useClientDetails } from '@/api/client/client'
import useCreateMembersGroups from '@/api/members/createMembersGroups'
import useCreateMembersOffices from '@/api/members/createMembersOffices'
import useDeleteMember from '@/api/member/delete'
import useDisableMembers from '@/api/members/disable'
import useEnableMembers from '@/api/members/enable'
import { useOffices } from '@/api/offices/get'
import { getErrorMessage } from '@/api/utils/get-error'
import AddUserGroupsModal from '@/common/components/AddUserGroupsModal/AddUserGroupsModal'
import AddUserOfficeModal from '@/common/components/AddUserOfficeModal/AddUserOfficeModal'
import EditUserModal from '@/common/components/EditUserModal/EditUserModal'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { Member } from '@/types/members'
import DeletionModal from '../DeletionModal'
import InactiveMembersModal from './InactiveMembersModal'
import UsersTable from './UsersTable'
import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { useEditMember } from '@/api/member/edit'

type UsersTabProps = {
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const UsersTab: FC<UsersTabProps> = ({ queryParams, updateQueryParam }) => {
  const { t } = useTranslation()

  const { mutateAsync: editMember } = useEditMember()
  const { mutateAsync: deleteMember } = useDeleteMember()
  const { mutateAsync: disableMembers } = useDisableMembers()
  const { mutateAsync: enableUsers } = useEnableMembers()
  const { mutateAsync: createMembersOffices } = useCreateMembersOffices()
  const { mutateAsync: createMembersGroups } = useCreateMembersGroups()
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: clientDetails } = useClientDetails()
  const [deleteMemberAlertIsOpen, setDeleteMemberAlertIsOpen] = useState(false)
  const [inactiveMemberAlertIsOpen, setInactiveMemberAlertIsOpen] = useState(false)
  const [userForDeletion, setUserForDeletion] = useState<string>('')
  const [usersForDeactivation, setMembersForDeactivation] = useState<string[]>([])
  const [usersForAddGroup, setUsersForAddGroup] = useState<string[]>([])
  const [usersForAddOffice, setUsersForAddOffice] = useState<string[]>([])
  const [memberToEdit, setMemberToEdit] = useState<Member>()
  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false)
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false)
  const [addOfficeModalOpen, setAddOfficeModalOpen] = useState(false)

  const quotaMetPercentage = Math.floor(
    ((clientDetails?.members?.active ?? 0) / (clientDetails?.users_quota ?? 0)) * 100
  )

  const exceptionAllowance = Math.ceil((clientDetails?.users_quota ?? 0) * 1.05) - (clientDetails?.members?.active ?? 0)
  const isQuotaExceeded = exceptionAllowance <= 0 || quotaMetPercentage > 105

  const quotaWarningMessage =
    (clientDetails?.users_quota ?? 0) <= 10
      ? ''
      : isQuotaExceeded
      ? t('users.membersQuotaWarning.exceededQuotaMessage', {
          parentAccount: clientDetails?.parent_name || "organization's",
        })
      : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
      ? t('users.membersQuotaWarning.metQuotaMessage', {
          exceptionAllowance: exceptionAllowance,
          metQuotaPercentage: quotaMetPercentage,
          parentAccount: clientDetails?.parent_name || "organization's",
        })
      : quotaMetPercentage >= 90
      ? t('users.membersQuotaWarning.warningMessage', { metQuotaPercentage: quotaMetPercentage })
      : ''

  const { successToast, errorToast } = useToast()

  function openEditMemberModal(user: User) {
    setMemberToEdit(user)
    setMemberModalIsOpen(true)
  }

  async function handleAddGroupsModal(userIds: string[]) {
    setUsersForAddGroup(userIds)
    setAddGroupModalOpen(true)
  }

  async function handleCloseAddGroupsModal() {
    setUsersForAddGroup([])
    setAddGroupModalOpen(false)
  }

  async function handleAddOfficeModal(userIds: string[]) {
    setUsersForAddOffice(userIds)
    setAddOfficeModalOpen(true)
  }

  async function handleCloseAddOfficeModal() {
    setUsersForAddOffice([])
    setAddOfficeModalOpen(false)
  }

  async function handleInactiveMemberAlert(userIds: string[]) {
    setMembersForDeactivation(userIds)
    setInactiveMemberAlertIsOpen(true)
  }

  async function handleCloseInactiveMemberAlert() {
    setMembersForDeactivation([])
    setInactiveMemberAlertIsOpen(false)
  }

  async function handleUserDeleteAlert(userId: string) {
    setUserForDeletion(userId)
    setDeleteMemberAlertIsOpen(true)
  }

  async function handleCloseUserDeleteAlert() {
    setUserForDeletion('')
    setDeleteMemberAlertIsOpen(false)
  }

  async function handleEditUser(member: Partial<Member>) {
    try {
      await editMember({ _id: member._id || '', params: member })
      successToast(t('users.userEdited'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEditUser'))
      throw e
    }
  }

  const handleDeleteUser = async () => {
    try {
      await deleteMember({ id: userForDeletion })
      successToast(t('users.userDeleted'))
      setDeleteMemberAlertIsOpen(false)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedDeleteUser'))
    }
  }

  const handleDisableMembers = async () => {
    try {
      await disableMembers({ users: usersForDeactivation })
      successToast(t('users.userDisabled'))
      handleCloseInactiveMemberAlert()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedDisableUser'))
    }
  }

  const handleEnableUser = async (users: string[]) => {
    try {
      await enableUsers({ users })
      successToast(t('users.userEnabled'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEnableUser'))
    }
  }

  async function handleAddGroups(values: any) {
    if (values.groups.length > 0) {
      try {
        await createMembersGroups(values)
        successToast('Groups Added Successfully')
        handleCloseAddGroupsModal()
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        errorToast(errorMessage || t('editUserModal.assignGroupsFail'))
      }
    }
  }

  async function handleAddOffice(values: any) {
    if (values.office) {
      try {
        await createMembersOffices(values)
        successToast('Office assigned Successfully')
        handleCloseAddOfficeModal()
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        errorToast(errorMessage || t('editUserModal.assignOfficeFail'))
      }
    }
  }

  return (
    <Box>
      <AddUserGroupsModal
        handleClose={handleCloseAddGroupsModal}
        handleSave={handleAddGroups}
        open={addGroupModalOpen}
        userIds={usersForAddGroup}
        groups={groups}
      />
      <AddUserOfficeModal
        handleClose={handleCloseAddOfficeModal}
        handleSave={handleAddOffice}
        open={addOfficeModalOpen}
        userIds={usersForAddOffice}
        offices={offices}
      />
      <DeletionModal
        confirmDeleteOpen={deleteMemberAlertIsOpen}
        handleCancelDelete={handleCloseUserDeleteAlert}
        deleteIntegrationHandler={handleDeleteUser}
        title={'Delete Member'}
        description={'Are you sure you want to delete this member?'}
      />
      <InactiveMembersModal
        confirmInactiveOpen={inactiveMemberAlertIsOpen}
        handleConfirmInactive={handleDisableMembers}
        handleCancelInactive={handleCloseInactiveMemberAlert}
      />
      <EditUserModal
        saveUserEdit={handleEditUser}
        open={memberModalIsOpen}
        setOpen={setMemberModalIsOpen}
        user={memberToEdit}
        onClose={() => setMemberToEdit(undefined)}
      />
      {quotaWarningMessage && (
        <Alert
          severity="warning"
          variant="filled"
          style={{
            margin: '8px 16px',
            backgroundColor: isQuotaExceeded
              ? theme.palette.red[900] + '10'
              : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
              ? theme.palette.orange[400] + '15'
              : theme.palette.blue[500] + '10',
            color: theme.palette.blueDianne[900],
          }}
          icon={
            <MessageIcon
              isQuotaExceeded={isQuotaExceeded}
              quotaMetPercentage={quotaMetPercentage}
              clientDetails={clientDetails}
            />
          }>
          <Typography variant="h3" style={{ margin: '0 0 8px', fontSize: '20px', fontWeight: 'bold' }}>
            {t('users.membersQuotaWarning.title')}
          </Typography>
          {quotaWarningMessage}
        </Alert>
      )}

      <UsersTable
        handleEditUser={openEditMemberModal}
        handleOnboardUser={handleEnableUser}
        handleOffboardUser={handleInactiveMemberAlert}
        handleAssignToGroups={handleAddGroupsModal}
        handleAssignToOffice={handleAddOfficeModal}
        offices={offices}
        groups={groups}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
      />
    </Box>
  )
}

const MessageIcon: FC<{
  isQuotaExceeded: boolean
  quotaMetPercentage: number
  clientDetails?: ClientDetailsResult
}> = ({ isQuotaExceeded, quotaMetPercentage, clientDetails }) => {
  const determineIcon = () => {
    if (isQuotaExceeded) {
      return faSquareExclamation
    } else if (quotaMetPercentage >= 100 && quotaMetPercentage <= 105) {
      return faTriangleExclamation
    } else if (quotaMetPercentage >= 90 && (clientDetails?.users_quota ?? 0) >= 10) {
      return faCircleExclamation
    } else {
      return faTriangleExclamation as IconProp
    }
  }

  const iconColor = isQuotaExceeded
    ? theme.palette.red[900]
    : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
    ? theme.palette.orange[400]
    : theme.palette.blue[500]

  return <FontAwesomeIcon icon={determineIcon() as IconProp} color={iconColor} />
}

export default UsersTab
