import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useGetCampaignReport } from '@/api/campaigns/campaign-report'
import { useCampaign } from '@/api/campaigns/get'
import { useCampaignUsers } from '@/api/campaigns/getUsers'
import { NEW_LAUNCH_ANIMATION_DELAY_TIME } from '@/api/constants'
import { useEducationModuleById } from '@/api/education-modules/module-by-id'
import FeedbackAnimation from '@/common/animations/FeedbackAnimation'
import CampainDetails from '@/common/components/CampaignDetails/CampaignDetails'
import Countdown from '@/common/components/Countdown/Countdown'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CompletionRate from './CompletionRate'
import EventsBarChart from './EventsBarChart'
import ReportAlert from './ReportAlert'
import TrainingCampaignUsersTable from './TrainingCampaignUsersTable'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'

const TrainingCampaignDashboard: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { _id } = useParams()
  const [isReportAlertOpen, setReportAlertOpen] = useState(false)

  const { data: campaignData, isPending: isPendingCampaign } = useCampaign(_id, { refetchInterval: 10_000 })
  const { data: allUsersData, isPending: isPendingUsers } = useCampaignUsers(
    { id: _id, limit: 0 },
    { refetchInterval: 10_000 }
  )
  const { data: asset } = useEducationModuleById(campaignData?.asset_ids[0], { enabled: !!campaignData })
  const { mutateAsync: getCampaignReport, isPending: isReportLoading } = useGetCampaignReport(() =>
    setReportAlertOpen(true)
  )
  if (isPendingCampaign || isPendingUsers || !campaignData || !allUsersData) {
    return <LoadingContainer />
  }

  const { results: users, total: usersCount } = allUsersData || {}

  const hasQuiz = campaignData?.asset_metadata?.include_quiz

  const isNEW =
    dayjs().valueOf() -
      dayjs(campaignData?.created_at).add(dayjs(campaignData?.created_at).utcOffset(), 'minutes').valueOf() <
    NEW_LAUNCH_ANIMATION_DELAY_TIME

  const totalCompleted = users.reduce(
    (acc, user) => (user.events.training_completed && (!hasQuiz || user.events.quiz_completed) ? acc + 1 : acc),
    0
  )

  const formattedEvents = users.reduce((acc, user) => {
    acc[user._id || user.username] = {
      started: user.events.training_opened,
      completed: (hasQuiz && user.events.quiz_completed) || !hasQuiz ? user.events.training_completed : 0,
    }
    return acc
  }, {})

  const CompletedTrainingChart: FC = () => {
    return (
      <div className={classes.chartContainer}>
        <TitleBar>
          <Typography color={'inherit'} variant={'subtitle2'}>
            {t(hasQuiz ? 'trainingCampaignDashboard.completedTraining' : 'trainingCampaignDashboard.watchedVideo')}
          </Typography>
        </TitleBar>
        <div style={{ height: '180px', margin: '30px' }}>
          <CompletionRate
            textId={
              hasQuiz ? 'trainingCampaignDashboard.completedTraining' : 'trainingCampaignDashboard.watchedTheVideo'
            }
            value={Math.ceil((totalCompleted / usersCount) * 100)}
            size={180}
          />
        </div>
      </div>
    )
  }
  const AvgScoreChart: FC = () => {
    return (
      <div className={classes.chartContainer}>
        <TitleBar>
          <Typography color={'inherit'} variant={'subtitle2'}>
            {t('trainingCampaignDashboard.avgScore')}
          </Typography>
        </TitleBar>
        <div style={{ height: '180px', margin: '30px' }}>
          <CompletionRate
            color={theme.palette.blueGray[900]}
            textId={'trainingCampaignDashboard.avgScore'}
            showPercent={false}
            value={Math.ceil(campaignData.score)}
            size={180}
          />
        </div>
      </div>
    )
  }

  return (
    <LayoutPageContent>
      <ReportAlert open={isReportAlertOpen} handleClose={() => setReportAlertOpen(false)} />
      {isPendingCampaign || !campaignData ? (
        <LoadingContainer />
      ) : (
        <>
          <PageHeader>
            <Tooltip title={!usersCount && t('trainingCampaignDashboard.noMembers')}>
              <Box>
                <LoadingButton
                  variant="contained"
                  onClick={() => getCampaignReport(campaignData._id)}
                  disabled={campaignData.status !== 'completed' || isReportLoading || !usersCount}
                  loading={isReportLoading}
                  loadingPosition="end">
                  {t('trainingCampaignDashboard.downloadReport')}
                </LoadingButton>
              </Box>
            </Tooltip>
          </PageHeader>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} md={4}>
                  <div className={classes.statsContainer}>
                    <TitleBar>
                      <Typography color={'inherit'} variant={'subtitle2'}>
                        {t('trainingCampaignDashboard.campaignDetails')}
                      </Typography>
                    </TitleBar>
                    <CampainDetails campaignData={campaignData} membersData={allUsersData} asset={asset} />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.statsContainer}>
                    <TitleBar>
                      <Typography color={'inherit'} variant={'subtitle2'}>
                        {t('trainingCampaignDashboard.timeFrame')}
                      </Typography>
                    </TitleBar>
                    <Box display="flex" flexDirection="column" padding={theme.spacing(6, 0)}>
                      <Countdown campaign={campaignData} />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                  {hasQuiz ? <AvgScoreChart /> : <CompletedTrainingChart />}
                </Grid>
                <Grid item xs={12} md={hasQuiz ? 8 : 12} xl={hasQuiz ? 8 : 12}>
                  <div className={classes.chartContainer}>
                    <TitleBar>
                      <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                        {t('trainingCampaignDashboard.eventsChart.activity')}
                      </Typography>
                    </TitleBar>
                    <EventsBarChart
                      data={formattedEvents}
                      campaignStart={campaignData.schedule.start}
                      campaignEnd={campaignData.schedule.end}
                    />
                  </div>
                </Grid>
                {hasQuiz ? (
                  <Grid item xs={12} md={4} xl={4}>
                    <CompletedTrainingChart />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid container direction={'column'} item xs={12}>
                  <CustomAccordion defaultChecked defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                        {t('trainingCampaignDashboard.usersTable.users')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TrainingCampaignUsersTable campaignId={_id} hasQuiz={hasQuiz} />
                    </AccordionDetails>
                  </CustomAccordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isNEW && <FeedbackAnimation />}
        </>
      )}
    </LayoutPageContent>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    statsContainer: {
      overflow: 'hidden',
      height: '100%',
      position: 'relative',
      border: '1.5px solid rgb(184 184 184)',
      borderRadius: '15px',
      background: theme.palette.white,
    },
    chartContainer: {
      height: '100%',
      maxHeight: '375px',
      paddingBottom: '30px',
      border: '1.5px solid rgb(184 184 184)',
      borderRadius: '15px',
      background: theme.palette.white,
    },
    userTableContainer: {
      flex: '1',
      marginBottom: theme.spacing(2),
      border: '1.5px solid rgb(184 184 184)',
      borderRadius: '15px',
    },
  })
)

const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#294D55',
  alignItems: 'center',
  padding: '8px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#E0EDEF',
  borderRadius: '15px 15px 0px 0px',
}))

const CustomAccordion = styled(Accordion)(() => ({
  margin: '0px',
  padding: '0px',
  background: '#E0EDEF',
  border: '1.5px solid rgb(184 184 184)',
  borderRadius: '15px',
  '.MuiAccordionDetails-root': {
    background: 'white',
    padding: '5px',
    border: 'none',
    minHeight: '0px',
    borderRadius: '0px 0px 15px 15px',
  },
  '.MuiButtonBase-root': {
    minHeight: '45px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
    padding: '0px',
  },
}))

export default TrainingCampaignDashboard
