import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, IconButton, Paper } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Shepherd, { StepOptions } from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'

import { useCampaignMicrotraining } from '@/api/campaigns/getMicrotraining'
import { CAMPAIGN_TYPES, EVENT_TYPES, LogEventParams } from '@/api/events/log'
import { usePhishingSimulation } from '@/api/phishing-simulations/get'
import CywarnessHeaderLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import CywarenessLogo from '@/assets/images/logo-dark.svg'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useDeviceDetection, { DeviceType } from '@/common/hooks/useDeviceDetection'
import useLogEvent from '@/common/hooks/useLogEvent'
import isLanguageRTL from '@/common/utils/isLanguageRTL'
import { theme } from '@/theme/theme'
import { CampaignMicrotraining, MicrotrainingIndicator } from '@/types/campaigns'
import { PhishingIndicator, PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import DevicePreview from './components/DevicePreview'
import Header from './components/Header'
import InvalidToken from './components/InvalidToken'

const ControlDots = ({ stepsCount, currentStepIndex }: { stepsCount: number; currentStepIndex: number }) => {
  const classes = useStyles()
  return (
    <Box className={classes.circles}>
      {Array.from({ length: stepsCount }).map((_, index) => {
        return (
          <Box
            key={index}
            className={currentStepIndex === index ? classes.currentCircle : classes.circle}
            id={'toStep' + index}
          />
        )
      })}
    </Box>
  )
}

const createShepherdTour = (
  data: CampaignMicrotraining | undefined,
  t: (key: string) => string,
  isRtl: boolean,
  logCampaignEvent: (() => void) | ((params: Omit<LogEventParams, 'campaignToken'>) => Promise<void>)
) => {
  let touchStartY = 0
  let scrollableElement: HTMLElement | null = null
  let isTouching = false
  const handleTouchStart = (e: TouchEvent) => {
    if (e.touches.length === 1) {
      touchStartY = e.touches[0].clientY
      isTouching = true
    }
  }

  const handleTouchMove = (e: TouchEvent) => {
    if (isTouching && e.touches.length === 1) {
      const touchCurrentY = e.touches[0].clientY
      const touchDifferenceY = touchCurrentY - touchStartY
      // Manually scroll the target element
      if (scrollableElement) scrollableElement.scrollTop -= touchDifferenceY
      e.preventDefault() // Prevent default scroll behavior
    }
  }

  const handleTouchEnd = () => {
    isTouching = false
  }

  const attachTouchListeners = (element: HTMLElement | null) => {
    scrollableElement = element?.querySelector('.shepherd-content') || null // Set your scrollable content here

    if (scrollableElement) {
      scrollableElement.addEventListener('touchstart', handleTouchStart)
      scrollableElement.addEventListener('touchmove', handleTouchMove)
      scrollableElement.addEventListener('touchend', handleTouchEnd)
    }
  }

  const removeTouchListeners = () => {
    if (scrollableElement) {
      scrollableElement.removeEventListener('touchstart', handleTouchStart)
      scrollableElement.removeEventListener('touchmove', handleTouchMove)
      scrollableElement.removeEventListener('touchend', handleTouchEnd)
    }
  }

  const classes = useStyles()
  if (!data) return null

  const handleTrainingStepChange = (currentStep: number) => {
    var pageIteration = {
      current_page: currentStep + 1,
      total_pages: data.indicators.message.length + 2,
    }
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_PAGE_ITERATION,
      campaignType: CAMPAIGN_TYPES.EMAIL,
      additionalData: { page_iteration: pageIteration },
    })
  }

  const renderCloseButton = (tour: Shepherd.Tour) => {
    const closeButtonContainer = document.createElement('div')
    closeButtonContainer.style.position = 'absolute'
    closeButtonContainer.style.top = '10px'
    closeButtonContainer.style.right = '10px'
    ReactDOM.render(
      <IconButton
        onClick={() => {
          tour.cancel()
          logCampaignEvent({
            eventType: EVENT_TYPES.FEEDBACK_CLOSED,
            campaignType: CAMPAIGN_TYPES.EMAIL,
          })
        }}>
        <FontAwesomeIcon icon={faX as IconProp} fontSize={10} color={theme.palette.blueGray[900]} />
      </IconButton>,
      closeButtonContainer
    )

    return closeButtonContainer
  }

  const renderStepNumber = (index: number) => {
    const numberContainer = document.createElement('div')
    numberContainer.style.position = 'absolute'
    numberContainer.style.top = '-10px'
    numberContainer.style.left = '-10px'
    ReactDOM.render(<StyledStepNumberCircle>{index + 1}</StyledStepNumberCircle>, numberContainer)
    return numberContainer
  }

  const getStepButtons = (index: number) => [
    {
      text: ReactDOMServer.renderToStaticMarkup(
        <FontAwesomeIcon icon={(isRtl ? faArrowRight : faArrowLeft) as IconProp} />
      ),
      classes: classes.shepherdButton,
      action: () => {
        tour.back()
        handleTrainingStepChange(index)
      },
      disabled: index === 0,
    },
    {
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <ControlDots stepsCount={data.indicators.message.length + 2} currentStepIndex={index} />
        ),
      classes: classes.shepherdButton,
      action: (e: any) => {
        if ((e.target.id as string).startsWith('toStep')) {
          tour.show(Number((e.target.id as string).charAt(6)))
        }
      },
    },
    {
      text: ReactDOMServer.renderToStaticMarkup(
        <FontAwesomeIcon icon={(isRtl ? faArrowLeft : faArrowRight) as IconProp} />
      ),
      classes: classes.shepherdButton,
      disabled: data.indicators.message.length + 1 === index,
      action: () => {
        tour.next()
        handleTrainingStepChange(index)
      },
    },
  ]

  const steps = [
    {
      id: 'welcome',
      title: t('feedbackPage.welcomeTitle'),
      text: t('feedbackPage.welcomeMessage'),
      attachTo: {
        element: '#title',
        on: 'bottom',
      },
      scrollY: true,
      when: {
        show: function (this: Shepherd.Step) {
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
            attachTouchListeners(this.el)
            this.tour.on('hide', () => {
              removeTouchListeners()
            })
          }
        },
      },
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      buttons: getStepButtons(0),
    },
    ...(data.indicators.message || []).map((indicator: MicrotrainingIndicator, indicatorIndex: number) => ({
      id: `indicator-${indicator.selector}`,
      title: indicator.title,
      text: indicator.description,
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      scrollY: true,
      modalOverlayOpeningRadius: 10,
      attachTo: {
        element: indicator.selector,
        on: 'bottom',
      },
      arrow: false,
      highlightClass: 'highlight',
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      when: {
        show: function (this: Shepherd.Step) {
          let stopDynamicPaddingRecursion = false
          const dynamicPadding = (element: any) => {
            const stepRect = element.getBoundingClientRect()
            const spotlightRect = this.target.getBoundingClientRect()
            if (spotlightRect.top - 16 > stepRect.top) {
              element.style.marginTop = theme.spacing(-2)
            } else {
              element.style.marginTop = theme.spacing(2)
            }
          }
          const repeatingDynamicPadding = async (element: any) => {
            if (!element || element.hidden || stopDynamicPaddingRecursion) return
            await new Promise<void>((resolve) => {
              setTimeout(() => {
                dynamicPadding(element)
                resolve()
              }, 250)
            })
            // Call recursively
            await repeatingDynamicPadding(element)
          }
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
            attachTouchListeners(this.el)
            const handleHide = () => {
              stopDynamicPaddingRecursion = true
              removeTouchListeners()
              this.tour.off('hide', handleHide)
              this.tour.off('cancel', handleHide)
            }
            this.tour.on('cancel', handleHide)
            this.tour.on('hide', handleHide)
            // Calculate in real-time whether the step is above or below the spotlight and adjust the margin accordingly.
            repeatingDynamicPadding(this.el)
          }
        },
      },
      buttons: getStepButtons(indicatorIndex + 1),
    })),
    {
      id: 'final',
      title: t('feedbackPage.finalTitle'),
      text: t('feedbackPage.finalMessage'),
      attachTo: {
        element: '#title',
        on: 'bottom',
      },
      highlightClass: 'highlight',
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      scrollY: true,
      when: {
        show: function (this: Shepherd.Step) {
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
            attachTouchListeners(this.el)
            this.tour.on('hide', () => {
              removeTouchListeners(this.el)
            })
          }
        },
      },
      buttons: [
        ...getStepButtons(data.indicators.message.length + 1),
        {
          text: t('feedbackPage.finish'),
          classes: classes.shepherdFinishButton,
          action: () => {
            tour.complete()
            logCampaignEvent({
              eventType: EVENT_TYPES.FEEDBACK_COMPLETED,
              campaignType: CAMPAIGN_TYPES.EMAIL,
            })
          },
        },
      ],
    },
  ]
  const tour: Shepherd.Tour = new Shepherd.Tour({
    defaultStepOptions: {
      scrollTo: { behavior: 'smooth', block: 'center' },
    },
    useModalOverlay: true,
    steps: steps as StepOptions[],
  })
  return tour
}

const buildMicrotraining = (
  simulation: PhishingSimulation,
  language?: string,
  difficulty?: number,
  vector?: string
): CampaignMicrotraining => {
  difficulty = difficulty || simulation.difficulties[0]
  const messageLanguage = language || simulation.languages.message[0]
  const landingPageLanguage = language || simulation.languages.landing_page[0]

  const messageHtml = simulation.html_contents.message
  const landingPageHtml = simulation.html_contents.landing_page

  const buildIndicators: (indicators: PhishingIndicator[]) => MicrotrainingIndicator[] = (indicators) =>
    indicators
      .filter((indicator) => indicator.difficulty === difficulty)
      .map((indicator) => ({
        selector: indicator.selector,
        title: indicator.title[messageLanguage],
        description: indicator.description[messageLanguage],
      }))
  return {
    sender: simulation.sender,
    subject: simulation.subject,
    logo: CywarenessLogo,
    message: {
      html: messageHtml,
      language: messageLanguage,
    },
    landing_page: {
      html: landingPageHtml,
      language: landingPageLanguage,
    },
    indicators: {
      message: buildIndicators(simulation.phishing_indicators?.message),
      landing_page: buildIndicators(simulation.phishing_indicators?.landing_page),
    },
    token: '',
    vector: vector,
  }
}

interface MicrotrainingProps {
  isPreview?: boolean
}
const Microtraining: FC<MicrotrainingProps> = ({ isPreview = false }) => {
  const theme = useTheme()
  const { log: logEvent } = useLogEvent()
  const { t, i18n } = useTranslation()
  const { simulationId, token } = useParams()
  const [isStart, setIsStart] = useState(false)
  const deviceType = useDeviceDetection()
  const {
    data: simulation,
    isPending: isSimulationPending,
    isError: isSimulationError,
  } = usePhishingSimulation(simulationId)

  const {
    data: microtraining,
    isPending: isMicrotrainingPending,
    isError: isMicrotrainingError,
  } = useCampaignMicrotraining(token)

  const loading = isSimulationPending && isMicrotrainingPending
  const error = isSimulationError && isMicrotrainingError
  const attackVector = simulation?.vectors[0] || microtraining?.vector
  const data = useMemo<CampaignMicrotraining | undefined>(
    () => (isPreview && simulation ? buildMicrotraining(simulation) : microtraining),
    [simulation, microtraining]
  )

  const logCampaignEvent =
    isPreview || !data?.token
      ? () => {}
      : (params: Omit<LogEventParams, 'campaignToken'>) => logEvent({ ...params, campaignToken: data.token })

  const tour = createShepherdTour(data, t, isLanguageRTL(data?.message.language), logCampaignEvent)
  useEffect(() => {
    if (data?.message.language) {
      i18n.changeLanguage(data.message.language)
    }
    setIsStart(true)
  }, [data?.message.language, i18n])

  useEffect(() => {
    if (isStart) {
      setTimeout(() => {
        tour?.start()
      })
      setIsStart(false)
    }
  }, [isStart])

  const handleOpenTraining = () => {
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_TIPS_CLICKED,
      campaignType: CAMPAIGN_TYPES.EMAIL,
    })
  }
  if (loading) return <LoadingContainer />
  if (error || !data) return <InvalidToken />

  return (
    <Box sx={{ height: '100vh' }}>
      <Header logo={CywarnessHeaderLogo} />
      <Box sx={{ width: '90%', margin: 'auto' }}>
        <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
          <Box sx={{ width: '100%', margin: 'auto', textAlign: 'right' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                if (tour) tour.start()
                handleOpenTraining()
              }}
              sx={{ marginBottom: theme.spacing(2), color: theme.palette.black }}>
              {t('feedbackPage.showTips')}
            </Button>
          </Box>
          <DevicePreview
            deviceType={deviceType as DeviceType}
            attackVector={attackVector as PhishingSimulationVector}
            data={data}
            isRtl={isLanguageRTL(data?.message.language)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const StyledStepNumberCircle = styled(Paper)(() => ({
  background: theme.palette.cyan[500],
  width: '22px',
  height: '22px',
  borderRadius: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
}))

const useStyles = makeStyles(() =>
  createStyles({
    shepherdStep: {
      position: 'fixed',
      maxWidth: '312px',
      width: '312px',
      height: '208px',
      maxHeight: '208px',
      borderRadius: '15px',
      paddingBottom: '208px',
      touchAction: 'manipulation',
      '& .shepherd-content': {
        height: '128px',
        overflowY: 'scroll',
        position: 'static',
        scrollBehavior: 'smooth',
        margin: theme.spacing(4.5, 2, 10, 0),
        paddingBottom: theme.spacing(4.5),
        pointerEvents: 'auto',
        '-webkit-overflow-scrolling': 'auto',
        '& .shepherd-header': {
          fontSize: '14px',
          padding: theme.spacing(0, 0.5, 0, 2),
          '& h3': {
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightSemiBold,
          },
          fontWeight: theme.typography.fontWeightSemiBold,
          background: 'none',
        },
        '& .shepherd-text': {
          fontSize: '14px',
          padding: theme.spacing(1, 0.5, 0, 2),
        },
      },
      '& .shepherd-footer': {
        position: 'absolute',
        bottom: '2px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    rtlShepherdStep: {
      direction: 'rtl',
    },
    shepherdButton: {
      padding: theme.spacing(0.2),
      background: 'none',
      color: theme.palette.black,
      '&:disabled': {
        color: theme.palette.grey[300],
        cursor: 'default',
        '&:hover': {
          color: theme.palette.grey[300],
        },
      },
      '&:not(:disabled):hover': {
        background: 'none',
        color: theme.palette.cyan[500],
      },
      '&:active': {
        background: 'none',
        color: theme.palette.cyan[500],
      },
    },
    shepherdFinishButton: {
      background: theme.palette.cyan[500],
      color: theme.palette.black,
      borderRadius: 100,
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: theme.typography.fontWeightMedium,
      padding: '6px 8px',
      margin: '0px 16px',
      '&:disabled': {
        color: theme.palette.grey[300],
        cursor: 'default',
        '&:hover': {
          color: theme.palette.grey[300],
        },
      },
      '&:not(:disabled):hover': {
        background: theme.palette.blueDianne[600],
        color: theme.palette.black,
      },
      '&:active': {
        background: theme.palette.blueDianne[800],
        color: theme.palette.black,
      },
    },
    circle: {
      width: '8px',
      height: '8px',
      background: 'white',
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: '50px',
    },
    currentCircle: {
      width: '8px',
      height: '8px',
      background: theme.palette.cyan[500],
      border: `1px solid ${theme.palette.cyan[500]}`,
      borderRadius: '50px',
    },
    circles: {
      display: 'flex',
      gap: theme.spacing(0.6),
    },
  })
)

export default Microtraining
