import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faBoxOpen,
  faComment,
  faEnvelope,
  faMicrochipAi,
  faPaintbrushPencil,
  faTrash,
  faHeart as heartIcon,
} from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { Box, Button, Card, CardActionArea, CardMedia, Collapse, Fade, IconButton, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CywarenessDefaultImage from '@/assets/images/cywareness_default.png'
import { LayoutContext } from '@/common/components/Layout/Layout'
import Tags from '@/common/components/Tags/Tags'
import { resizeImage } from '@/common/utils/cloudinary-utils'
import { useAuth } from '@/context/Auth'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { useDraggable } from '@dnd-kit/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContentLibraryContext } from '../ContentLibrary'

type SimulationCardProps = {
  simulation: PhishingSimulation
  showPreview?: (template: PhishingSimulation) => void
  launchModule?: (template: PhishingSimulation) => void
  deleteModule?: (template: PhishingSimulation) => void
  saveModule?: (id: string) => void
  isSaved?: boolean
  hoverState?: -1 | 0 | 1 | null
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  carouselTopic?: string
}

const SimulationCard: FC<SimulationCardProps> = memo(
  ({
    simulation,
    showPreview,
    launchModule,
    deleteModule,
    saveModule,
    isSaved,
    hoverState,
    onMouseLeave,
    onMouseEnter,
    carouselTopic,
  }) => {
    const { isMultiSimulationSideMenuOpen, setDraggedSimulation } = useContext(ContentLibraryContext)
    const { addToSimulationPackage } = useContext(LayoutContext)
    const { attributes, listeners, setNodeRef, active } = useDraggable({
      id: simulation.id + '_' + carouselTopic,
    })
    const { user } = useAuth()
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const hoverClass =
      hoverState === null
        ? ''
        : hoverState === 0
        ? classes.scale
        : hoverState && hoverState > 0
        ? classes.translateLeft
        : classes.translateRight

    const vectorIcon = useMemo(() => {
      switch (simulation?.vectors[0]) {
        case 'sms':
          return <FontAwesomeIcon icon={faComment as IconProp} fontSize={16} />
        case 'email':
          return <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={16} />
        case 'whatsapp':
          return <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={16} />
      }
    }, [simulation?.vectors])

    const handleLike = useCallback(() => {
      saveModule && saveModule(simulation.id)
    }, [saveModule, simulation.id])

    const handleDelete = useCallback(() => {
      deleteModule && deleteModule(simulation)
    }, [deleteModule, simulation])

    const handleLaunch = useCallback(() => {
      launchModule && launchModule(simulation)
    }, [launchModule, simulation])

    // const handleEdit = () => {
    //   navigate(`/content-library/simulations/edit/${simulation.id}`)
    // }

    const openTemplate = useCallback(() => {
      if (!simulation?.id) {
        return
      }
      showPreview && showPreview(simulation)
    }, [simulation, showPreview])

    useEffect(() => {
      if (active?.id === simulation.id + '_' + carouselTopic) setDraggedSimulation(simulation)
    }, [active, simulation, setDraggedSimulation, carouselTopic])

    const badge = useMemo(() => {
      if (simulation.ai_generated && !simulation.is_public) {
        return (
          <>
            <Box className={classes.badgeAnchor}>
              <FontAwesomeIcon icon={faMicrochipAi as IconProp} />{' '}
              <Typography variant="body2">{t('simulationLibrary.aiGenerated')}</Typography>
            </Box>
            <Box className={classes.badgeWrap} />
          </>
        )
      } else if (
        simulation.organization_id === user?.current_client_id &&
        !simulation.ai_generated &&
        !simulation.is_public
      ) {
        return (
          <>
            <Box className={classes.badgeAnchor}>
              <FontAwesomeIcon icon={faPaintbrushPencil as IconProp} />{' '}
              <Typography variant="body2">{t('simulationLibrary.custom')}</Typography>
            </Box>
            <Box className={classes.badgeWrap} />
          </>
        )
      }
    }, [simulation, user])
    return (
      <Box className={`${classes.root} ${hoverClass}`}>
        <Box
          ref={isMultiSimulationSideMenuOpen && active?.id === simulation.id + '_' + carouselTopic ? setNodeRef : null}
          {...listeners}
          {...attributes}
          overflow="visible"
          position="relative"
          width="max-content">
          {badge}
          <Card className={classes.card} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <CardActionArea onClick={openTemplate}>
              <Box className={classes.contentContainer}>
                <Box className={classes.overlay} />
                {!active && (
                  <Box className={classes.topActionsContainer}>
                    <Fade in={hoverState === 0}>
                      <IconButton
                        disabled={!simulation.ai_generated}
                        onClick={(e) => {
                          handleDelete()
                          e.stopPropagation()
                        }}
                        size="small"
                        color={'inherit'}>
                        <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
                      </IconButton>
                    </Fade>
                    <Fade in={isSaved === true || hoverState === 0}>
                      <IconButton
                        onClick={(e) => {
                          handleLike()
                          e.stopPropagation()
                        }}
                        size="small"
                        color={'inherit'}>
                        <FontAwesomeIcon
                          icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
                          fontSize={22}
                          color={theme.palette.pink[500]}
                        />
                      </IconButton>
                    </Fade>
                    {/* <Fade in={simulation.organization_id === clientData?._id && hoverState === 0}>
                  <IconButton
                    disabled={simulation.organization_id !== clientData?._id || simulation.ai_generated}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleEdit()
                    }}
                    size="small"
                    color={'inherit'}>
                    <EditIcon />
                  </IconButton>
                </Fade> */}
                  </Box>
                )}

                <Fade in={hoverState === 0} timeout={{ enter: 500, exit: 500 }}>
                  <CardMedia
                    component="img"
                    alt={`Image of ${simulation.name}`}
                    height="200"
                    image={resizeImage(simulation.landing_page_thumbnail ?? CywarenessDefaultImage, 800, 600)}
                    title={simulation.name}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  />
                </Fade>

                <Fade in={hoverState !== 0} timeout={{ enter: 500, exit: 500 }}>
                  <CardMedia
                    component="img"
                    alt={`Image of ${simulation.name}`}
                    height="200"
                    image={resizeImage(simulation.template_thumbnail || CywarenessDefaultImage, 800, 600)}
                    title={simulation.name}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  />
                </Fade>

                <Box className={classes.container}>
                  <Box className={classes.titleContainer}>
                    <Typography className={classes.title} noWrap variant="h6">
                      {simulation.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {!active && (
                <Collapse in={hoverState === 0}>
                  <Box className={classes.buttonContainer}>
                    <Box>
                      <Box className={classes.stat} style={{ marginBottom: theme.spacing(1) }}>
                        <Typography variant="subtitle2" className={classes.statKey}>
                          {t('simulationLibrary.topics')}:{' '}
                        </Typography>
                        <Tags
                          useChips={false}
                          tags={simulation.tags.map((tag) => ({
                            label: tag,
                            color: 'transparent',
                            textColor: theme.palette.common.black,
                          }))}
                          limit={1}
                        />
                      </Box>
                      <Box className={classes.stat}>
                        <Typography variant="subtitle2" className={classes.statKey}>
                          {t('simulationLibrary.difficulty')}: {simulation?.difficulties.join(', ')}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.statKey}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: theme.spacing(0.5),
                          }}>
                          {t('simulationLibrary.vector')}:{vectorIcon}
                        </Typography>
                      </Box>
                    </Box>
                    {isMultiSimulationSideMenuOpen ? (
                      <Button
                        onClick={(e) => {
                          addToSimulationPackage(simulation)
                          e.stopPropagation()
                        }}
                        variant={'contained'}
                        size="small"
                        endIcon={<FontAwesomeIcon icon={faBoxOpen as IconProp} />}>
                        {t('simulationLibrary.addTo')}
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => {
                          handleLaunch()
                          e.stopPropagation()
                        }}
                        variant={'contained'}
                        size="small">
                        {t('simulationLibrary.launch')}
                      </Button>
                    )}
                  </Box>
                </Collapse>
              )}
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    )
  }
)

// TODO: replace with styled component!
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    transition: 'transform 300ms ease 100ms',
    zIndex: 10,
    width: 'max-content',
    willChange: 'transform, opacity', // Browser optimization hint
  },
  card: {
    width: 350,
    minHeight: 200,
    position: 'relative',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: 0,

    overflow: 'hidden',
    transition: 'transform 300ms ease-out',
    willChange: 'transform, opacity', // Improve animation performance
  },
  badgeAnchor: {
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(6.5),
    right: theme.spacing(-1),
    background: theme.palette.cyan[100],
    color: theme.palette.blueDianne[900],
    padding: theme.spacing(0.5, 1),
    pointerEvents: 'none',
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  badgeWrap: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(6),
    right: theme.spacing(-1.1),
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '4px 0 0 10px',
    borderColor: 'transparent transparent transparent #9bcad1',
    pointerEvents: 'none',
  },
  duration: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  topActionsContainer: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(1),
    right: theme.spacing(1.5),
    display: 'flex',
    color: theme.palette.common.black,

    transition: 'opacity 300ms, transform 300ms',
    willChange: 'opacity, transform',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    pointerEvents: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    position: 'relative',
    zIndex: 10,
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    zIndex: 2,
    pointerEvents: 'none',
  },
  title: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.black,
    zIndex: 1,
    fontWeight: 400,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
  },
  contentContainer: {
    position: 'relative',
    height: 200,
    overflow: 'hidden',
    willChange: 'opacity, transform',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
  },
  statKey: { marginRight: theme.spacing(1) },
  tooltip: {
    padding: theme.spacing(1, 0.5),
  },
  cardActions: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.cyan[500],
    transition: 'all 300ms ease 100ms',
    padding: theme.spacing(1, 2),
  },
  translateLeft: {
    transform: 'translateX(-5%)',
  },
  translateRight: {
    transform: 'translateX(5%)',
  },
  scale: {
    transform: 'scale(1.05) translateY(-7%)',
  },
  overlay: {
    position: 'absolute',
    height: 80,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'linear-gradient(to top, white, #f2f2f22b)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    zIndex: 1,
    transition: 'opacity 300ms, transform 300ms',
  },
  vectorIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
}))

export default SimulationCard
