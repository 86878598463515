import ProtectedRoute from '@/Routes/ProtectedRoute'
import { indexToTab, tabToIndex } from '@/common/utils/tab-utils'
import { Box, Button, Card, Grid, IconButton, InputBase, TableCell, TableRow, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, SyntheticEvent, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import NewslettersLibrary from './NewslettersLibrary/NewslettersLibrary'
import VideosLibrary from './VideosLibrary/VideosLibrary'
import DifficultyFilter from '@/common/components/DifficultyFilter/DifficultyFilter'
import { LayoutContext } from '@/common/components/Layout/Layout'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import VectorFilter from '@/common/components/VectorFilter/VectorFilter'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'
import { useContentLibrary } from '@/context/ContentLibraryContext/useContentLibrary'
import { theme } from '@/theme/theme'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBoxOpen,
  faClapperboardPlay,
  faFishingRod,
  faFlaskRoundPotion,
  faGrid2,
  faList,
  faMagnifyingGlass,
  faMicrochipAi,
  faUpload,
  faX,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import SimulationCard from './SimulationsLibrary/SimulationCard'
import SimulationPackageMenu from './SimulationsLibrary/SimulationPackageMenu'
import SimulationsLanguagesFilter from './SimulationsLibrary/SimulationsLanguagesFilter'
import SimulationsLibrary from './SimulationsLibrary/SimulationsLibrary'
import SimulationsTagsFilter from './SimulationsLibrary/SimulationsTagsFilter'
import VideoLanguagesFilter from './VideosLibrary/VideoLanguagesFilter'
import VideoTagsFilter from './VideosLibrary/VideoTagsFilter'
import { TalbeColumn } from '@/common/components/Table'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import { throttle } from 'lodash'
// import QuizzesLibrary from './QuizzesLibrary/QuizzesLibrary'
// import GamesLibrary from './GamesLibrary/GamesLibrary'

const tabIndexMap = {
  '/content-library/videos': 0,
  '/content-library/simulations': 1,
  // '/content-library/quizzes': 2,
  // '/content-library/games': 3,
}

const defaultContentLibraryContextValues = {
  isMultiSimulationSideMenuOpen: true,
  setIsMultiSimulationSideMenuOpen: (e: boolean) => {},
  setDraggedSimulation: (e: PhishingSimulation) => {},
  setDraggedColumns: (e: TalbeColumn[] | null) => {},
}

export const ContentLibraryContext = createContext(defaultContentLibraryContextValues)

const ContentLibrary: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigateWithLayoutBlocker()
  const { pathname } = useLocation()
  const [currentTab, setCurrentTab] = useState(tabToIndex(tabIndexMap, pathname))
  const [isTableView, setIsTableView] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isMultiSimulationSideMenuOpen, setIsMultiSimulationSideMenuOpen] = useState(false)
  const { queryFilters, setQueryFilters } = useContentLibrary()
  const { addToSimulationPackage } = useContext(LayoutContext)
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } })
  const sensors = useSensors(pointerSensor)
  const [draggedColumns, setDraggedColumns] = useState<TalbeColumn[] | null>(null)
  const [draggedId, setDraggedId] = useState<string | null>(null)
  const [draggedSimulation, setDraggedSimulation] = useState<PhishingSimulation | null>(null)
  function resetTags() {
    setQueryFilters((prevState) => ({
      ...prevState,
      tags: [],
    }))
  }

  const handleTabChange = (e: SyntheticEvent<Element, Event>, index: number) => {
    if (!isMultiSimulationSideMenuOpen) setCurrentTab(index)
    navigateTo(indexToTab(tabIndexMap, index))
    // we need to reset the tags when we change tabs because the tags are different for each tab
    resetTags()
  }

  const handleTagClick = (tag: string) => {
    const newTags = queryFilters.tags || []
    if (newTags.includes(tag)) {
      newTags.splice(newTags.indexOf(tag), 1)
    } else {
      newTags.push(tag)
    }
    setQueryFilters((prevState) => ({
      ...prevState,
      tags: newTags,
    }))
  }

  const handleSearch = useCallback((value: string) => {
    setQueryFilters((prevState) => ({
      ...prevState,
      name: value.trimStart().trimEnd(),
    }))
  }, [])

  const handleUploadVideo = () => {
    navigateTo('/content-library/videos/create')
  }

  const toggleView = () => {
    setIsTableView(!isTableView)
  }

  const handleDragStart = useCallback(
    throttle((event) => {
      setDraggedId(event.active.id as any)
    }, 100),
    []
  )

  const handleDragEnd = useCallback(
    throttle((event) => {
      if (event.over && draggedSimulation) {
        addToSimulationPackage(draggedSimulation)
      }
    }, 100),
    [draggedSimulation, addToSimulationPackage]
  )

  useEffect(() => {
    // in case of a breadcrumb change, we need to set the current tab
    setCurrentTab(tabToIndex(tabIndexMap, pathname))
  }, [pathname])

  useEffect(() => {
    if (pathname === '/content-library') {
      navigateTo('/content-library/videos')
    }
  }, [navigateTo, pathname])

  const languageFilterProps = {
    label: t('contentLibrary.language'),
    selectedLanguages: queryFilters.languages,
    onChange: (languages) => {
      setQueryFilters((prevState) => ({ ...prevState, languages }))
    },
  }

  const draggedOverlayContent = useMemo(() => {
    if (!draggedId || !draggedSimulation) return <></>
    return (
      <Box
        sx={{
          transform: 'rotate(4deg)',
        }}>
        {draggedSimulation &&
          (draggedId.endsWith('_table') ? (
            <StyledTableRowWrapper>
              <TableRow>
                {draggedColumns?.map((col, index) => (
                  <TableCell key={`${col.id}-${index}`} align={index ? 'center' : 'left'}>
                    {col.component && col.component(draggedSimulation)}
                    {col.format && col.format(draggedSimulation)}
                    {!col.component && !col.format}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableRowWrapper>
          ) : (
            <SimulationCard key={draggedSimulation.id} simulation={draggedSimulation}></SimulationCard>
          ))}
      </Box>
    )
  }, [draggedId, draggedSimulation, draggedColumns])

  return (
    <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      {currentTab === 1 && (
        <SimulationPackageMenu
          isMultiSimulationSideMenuOpen={isMultiSimulationSideMenuOpen}
          setIsMultiSimulationSideMenuOpen={setIsMultiSimulationSideMenuOpen}
        />
      )}

      <Box style={{ marginLeft: isMultiSimulationSideMenuOpen && currentTab === 1 ? `${400}px` : 0 }}>
        <LayoutPageContent>
          <PageHeader>
            <Box display="flex" alignItems="center" gap={2}>
              {currentTab === 0 && (
                <Button
                  variant="outlined"
                  onClick={handleUploadVideo}
                  startIcon={<FontAwesomeIcon icon={faUpload as IconProp} />}>
                  {t('videoLibrary.uploadVideo')}
                </Button>
              )}
              {currentTab === 1 && (
                <Box display="flex" gap={1}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsMultiSimulationSideMenuOpen(true)
                    }}
                    startIcon={<FontAwesomeIcon icon={faBoxOpen as IconProp} />}>
                    {t('simulationLibrary.buildPackage')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigateTo('/content-library/simulations/ai-simulation-builder')
                    }}
                    startIcon={<FontAwesomeIcon icon={faMicrochipAi as IconProp} />}>
                    {t('simulationLibrary.createAiTemplate')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigateTo('/content-library/simulations/create')
                    }}
                    startIcon={<FontAwesomeIcon icon={faFlaskRoundPotion as IconProp} />}>
                    {t('simulationLibrary.createTemplate')}
                  </Button>
                </Box>
              )}
              {(currentTab === 0 || currentTab === 1) && (
                <IconButton id={'gridOrListSwitch'} onClick={toggleView} className={classes.viewToggle}>
                  <FontAwesomeIcon icon={(isTableView ? faGrid2 : faList) as IconProp} fontSize={20} />
                </IconButton>
              )}
            </Box>
          </PageHeader>
          <Grid item xs={12} xl={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <MainTabsWrapper>
                <MainTabs value={currentTab} onChange={handleTabChange}>
                  <MainTab
                    iconPosition="start"
                    icon={<FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={20} />}
                    label={t('contentLibrary.videos')}
                  />
                  <MainTab
                    iconPosition="start"
                    icon={<FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={20} />}
                    label={t('contentLibrary.simulations')}
                  />
                  {/* <MainTab
                  iconPosition="start"
                  icon={<FontAwesomeIcon icon={faBlockQuestion as IconProp} fontSize={20} />}
                  label={t('contentLibrary.quizzes')}
                />
                <MainTab
                  iconPosition="start"
                  icon={<FontAwesomeIcon icon={faGameConsoleHandheld as IconProp} fontSize={20} />}
                  label={t('contentLibrary.games')}
                /> */}
                </MainTabs>
              </MainTabsWrapper>
            </Box>
            <MainTabPage>
              <Card>
                <Box display="flex" alignItems="center">
                  <Box width="100%">
                    <InputBase
                      fullWidth
                      value={searchValue}
                      className={classes.searchInput}
                      endAdornment={
                        queryFilters.name ? (
                          <IconButton
                            size="small"
                            onClick={() => {
                              setQueryFilters((prevState) => ({
                                ...prevState,
                                name: '',
                              }))
                              setSearchValue('')
                            }}>
                            <FontAwesomeIcon icon={faX as IconProp} width={16} />
                          </IconButton>
                        ) : (
                          <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={16} />
                        )
                      }
                      placeholder={t('contentLibrary.search')}
                      onChange={(e) => {
                        const value = e.target.value
                        setSearchValue(value)
                        handleSearch(value)
                      }}
                    />
                  </Box>
                  <Box>
                    {currentTab === 0 ? <VideoLanguagesFilter {...languageFilterProps} /> : null}
                    {currentTab === 1 ? (
                      <div style={{ display: 'flex' }}>
                        <SimulationsLanguagesFilter {...languageFilterProps} />
                        <VectorFilter
                          values={queryFilters.vectors ?? []}
                          onChange={(vectors) => {
                            setQueryFilters((prevState) => ({
                              ...prevState,
                              vectors,
                            }))
                          }}
                          label={t('simulationLibrary.vector')}
                        />
                        <DifficultyFilter
                          values={queryFilters.difficulties ?? []}
                          onChange={(difficulties) => {
                            setQueryFilters((prevState) => ({
                              ...prevState,
                              difficulties,
                            }))
                          }}
                          label={t('simulationLibrary.difficulty')}
                        />
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <div className={classes.tagsFilter}>
                  {currentTab === 0 ? (
                    <VideoTagsFilter selectedTags={queryFilters.tags} onTagClick={handleTagClick} />
                  ) : null}
                  {currentTab === 1 ? (
                    <SimulationsTagsFilter queryParams={queryFilters} setQueryParams={setQueryFilters} />
                  ) : null}
                </div>
                <Box margin={theme.spacing(0, -2)}>
                  <Routes>
                    <Route
                      path="videos"
                      element={
                        <ProtectedRoute>
                          <VideosLibrary isTableView={isTableView} queryFilters={queryFilters} />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="newsletters"
                      element={
                        <ProtectedRoute>
                          <NewslettersLibrary />
                        </ProtectedRoute>
                      }
                    />

                    {/* <Route
              path="quizzes"
              element={
                <ProtectedRoute>
                  <QuizzesLibrary />
                </ProtectedRoute>
              }
            />

            <Route
              path="games"
              element={
                <ProtectedRoute>
                  <GamesLibrary />
                </ProtectedRoute>
              }
            /> */}

                    <Route
                      path="simulations"
                      element={
                        <ProtectedRoute>
                          <ContentLibraryContext.Provider
                            value={{
                              isMultiSimulationSideMenuOpen,
                              setIsMultiSimulationSideMenuOpen,
                              setDraggedSimulation,
                              setDraggedColumns,
                            }}>
                            <SimulationsLibrary queryFilters={queryFilters} isTableView={isTableView} />
                          </ContentLibraryContext.Provider>
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </Box>
              </Card>
            </MainTabPage>
          </Grid>
        </LayoutPageContent>
      </Box>
      <StyledDragOverlayWrapper>
        <DragOverlay>{draggedOverlayContent}</DragOverlay>
      </StyledDragOverlayWrapper>
    </DndContext>
  )
}

const StyledTableRowWrapper = styled(Box)(() => ({
  background: theme.palette.white,
  borderRadius: '10px',
  border: `1px solid ${theme.palette.grey[300]}`,
}))
const StyledDragOverlayWrapper = styled(Box)(() => ({
  position: 'absolute',
  zIndex: 1200,
  pointerEvents: 'none', // Prevent the overlay from blocking interactions
  willChange: 'opacity', // Only optimize properties managed by @dnd-kit
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    searchInput: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2),
    },
    tagsFilter: {
      margin: theme.spacing(1, -1.5, 2),
    },
    viewToggle: {
      color: theme.palette.blueGray[900],
      marginLeft: theme.spacing(-1),
    },
  })
)

export default ContentLibrary
