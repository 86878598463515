import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useChangeReportStatus, useReport } from '@/api/reports/reports'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import ReportCalendar from './ReportCalendar'
import ReportDetails from './ReportDetails'
import ReportFindings from './ReportFindings'
import ReportHeaders from './ReportHeaders'

const ResponseConsoleReport = () => {
  const { t } = useTranslation()
  const { _id } = useParams()
  const { data, isLoading } = useReport({ _id: _id! })
  const { mutateAsync: changeReportStatus } = useChangeReportStatus()
  const status: 'safe' | 'pending' | 'malicious' =
    data?.is_phishing === false ? 'safe' : data?.is_phishing ? 'malicious' : 'pending'
  const classes = useStyles({ status })

  const { errorToast } = useToast()

  const handleStatusChange = async (is_phishing: boolean) => {
    try {
      if (data?.is_phishing !== is_phishing) {
        if (_id) {
          await changeReportStatus({ is_phishing, _id })
        }
      }
    } catch (error) {
      errorToast('Error changing report status')
    }
  }

  return (
    <LayoutPageContent>
      <PageHeader>
        <Box display="flex" gap={2}>
          <Button
            disabled={!data}
            onClick={() => handleStatusChange(false)}
            sx={{ background: theme.palette.green[500], color: theme.palette.white }}
            variant={'contained'}>
            {t('reportPage.details.safe')}
          </Button>
          <Button
            disabled={!data}
            onClick={() => handleStatusChange(true)}
            sx={{ background: theme.palette.red[900], color: theme.palette.white }}
            variant={'contained'}>
            {t('reportPage.details.malicious')}
          </Button>
        </Box>
      </PageHeader>

      {isLoading ? (
        <LoadingContainer />
      ) : data ? (
        <>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gap={4}>
              <Card className={classes.nestedRow}>
                <ReportDetails data={data} />
              </Card>
              <Card className={classes.nestedRow}>
                <Typography gutterBottom variant={'button'}>
                  {t('reportPage.headers', {
                    value: Object.keys(data.headers).length ?? 0,
                  })}
                </Typography>
                <ReportHeaders data={data.headers} />
              </Card>
            </Box>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Card>
              <Typography gutterBottom variant={'button'}>
                {t('reportPage.calendarTitle')}
              </Typography>
              <ReportCalendar data={data} />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <ReportFindings data={data} />
            </Card>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <NoResultsContainer text={'reportPage.noResults'} />
        </Grid>
      )}
    </LayoutPageContent>
  )
}

const useStyles = makeStyles<Theme, { status: 'safe' | 'pending' | 'malicious' }>((theme) =>
  createStyles({
    nestedRow: { height: 250, padding: theme.spacing(1, 2, 3), maxHeight: 250 },
  })
)

export default ResponseConsoleReport
