import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ResponsiveBubble } from '@nivo/circle-packing'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { shortenString } from '@/common/utils/shortenString'
import { useMemberStatsById } from '@/api/member/get-stats'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'

type ExposureChartProps = {
  id: string
}

export default function ExposureChart({ id }: ExposureChartProps) {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: memberStats } = useMemberStatsById({ id: id })

  const root = {
    label: 'parent',
    children: (memberStats?.exposure.filter((d) => d.value > 0) ?? []).map((d) => ({
      label: d.label,
      children: [d],
    })),
  }

  return (
    <>
      {!memberStats || memberStats.exposure.length === 0 ? (
        <NoResultsContainer text={'userProfile.noData'} />
      ) : (
        <ResponsiveBubble
          root={root}
          theme={{
            tooltip: {
              container: { background: 'transparent', boxShadow: 'none' },
            },
          }}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          leavesOnly
          identity="label"
          value="value"
          enableLabel
          label={({ data }) => shortenString(t(`userProfile.exposureTags.${data.label}`), 20)}
          colorBy="label"
          colors={{ scheme: 'set2' }}
          padding={6}
          labelSkipRadius={20}
          labelTextColor={[theme.palette.text.primary]}
          borderWidth={2}
          borderColor={{ from: 'color' }}
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'none',
              color: 'inherit',
              rotation: -45,
              lineWidth: 5,
              spacing: 8,
            },
          ]}
          fill={[{ match: { depth: 1 }, id: 'lines' }]}
          animate={true}
          motionStiffness={90}
          motionDamping={12}
          tooltip={(point) => {
            return (
              <div className={classes.tooltip}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    background: point.color,
                    marginRight: 8,
                  }}
                />
                <Typography variant={'subtitle2'} noWrap>
                  {point.data.label}: {point.data.value}
                </Typography>
              </div>
            )
          }}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      background: theme.palette.background.secondaryGradient,
      borderRadius: 100,
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      fontWeight: 700,
      boxShadow: theme.shadows[6],
      boxSizing: 'border-box',
      border: `1px solid rgba(255,255,255,0.6)`,
    },
  })
)
