import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
// @ts-ignore
import * as Yup from 'yup'

import { useRedeemPromoCode } from '@/api/profile/redeem-promo-code'
import { AppliedPromoConfigurations } from '@/types/promoCodes'
import { theme } from '@/theme/theme'
import TextField from '@/common/components/Inputs/TextFieldV2'
import { Form, Formik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'

const RedeemPromoCode: FC = () => {
  const { t } = useTranslation()
  const [promoCodeError, setPromoCodeError] = useState(false)
  const [redeemedCofiguration, setRedeemedCofiguration] = useState<AppliedPromoConfigurations | null>(null)
  const { mutateAsync: redeemPromoCode, isPending, isSuccess } = useRedeemPromoCode()
  const initialValues = { promoCode: '' }

  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    if (values.promoCode) {
      try {
        const response = await redeemPromoCode({ code: values.promoCode })
        setRedeemedCofiguration(response.configurations)
      } catch (e) {
        setPromoCodeError(true)
      }
      resetForm()
    }
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize validateOnMount>
      {({ values }) => (
        <Form>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography fontWeight={theme.typography.fontWeightSemiBold} fontSize={18}>
              {t('settings.adminDetails.promoCode.promoCode')}
            </Typography>
            {promoCodeError ? (
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={faTriangleExclamation as IconProp}
                  color={theme.palette.red[900]}
                  fontSize={25}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <Typography paddingTop={0.2}>
                  <Trans
                    i18nKey="settings.adminDetails.promoCode.errors.failedToRedeem"
                    components={{ 1: <strong /> }}
                  />
                </Typography>
              </Box>
            ) : isSuccess ? (
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color={theme.palette.green[500]}
                  fontSize={25}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <Typography paddingTop={0.2}>
                  <Trans
                    i18nKey="settings.adminDetails.promoCode.success.redeemed"
                    values={{
                      campaignsQuota: redeemedCofiguration?.campaigns_quota,
                      usersQuota: redeemedCofiguration?.users_quota,
                    }}
                    components={{ 1: <strong /> }}
                  />
                </Typography>
              </Box>
            ) : null}
            <TextField label={t('settings.adminDetails.promoCode.promoCodeLabel')} name="promoCode" />
            <LoadingButton type="submit" variant="contained" disabled={!values.promoCode} loading={isPending}>
              {t('settings.adminDetails.promoCode.redeem')}
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default RedeemPromoCode
