import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EngagementChart from './EngagementChart'
import ExposureChart from './ExposureChart'
import UserCampaignsTable from './UserCampaignsTable'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { useMemberById } from '@/api/member/get'
import { useOffices } from '@/api/offices/get'
import { getErrorMessage } from '@/api/utils/get-error'
import AwarenessScore from '@/common/components/AwarenessScore/AwarenessScore'
import EditUserModal from '@/common/components/EditUserModal/EditUserModal'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import Tags from '@/common/components/Tags/Tags'
import useToast from '@/common/hooks/useToast'
import { Member } from '@/types/members'
import { useEditMember } from '@/api/member/edit'

const UserProfile: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { _id } = useParams()
  const { data: memberData, isLoading } = useMemberById({ id: _id })
  const { mutateAsync: editMember } = useEditMember()
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const userGroups = groups && memberData ? groups.filter((group) => memberData.member_of.includes(group._id)) : []
  const [userModalIsOpen, setUserModalIsOpen] = useState(false)
  const { successToast, errorToast } = useToast()
  const { t } = useTranslation()

  function handleOpenModal() {
    setUserModalIsOpen(true)
  }

  async function handleEditUser(member: Partial<Member>) {
    try {
      await editMember({ _id: member._id || '', params: member })
      successToast(t('userProfile.userEdited'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEditUser'))
      throw e
    }
  }

  return (
    <LayoutPageContent>
      <EditUserModal
        saveUserEdit={handleEditUser}
        open={userModalIsOpen}
        setOpen={setUserModalIsOpen}
        user={memberData}
        onClose={() => {}}
      />
      <PageHeader>
        <Button color={'inherit'} variant={'outlined'} onClick={handleOpenModal}>
          {t('userProfile.editUser')}
        </Button>
      </PageHeader>
      <Grid item xs={12}>
        <Card>
          <Box display="flex" alignItems="center">
            <Typography className={classes.title} gutterBottom variant={'h5'}>
              {memberData ? `${memberData.first_name} ${memberData.last_name} ` : ''}
              {offices && memberData
                ? `| ${offices?.find((office) => office._id === memberData.office_id)?.name || ''}`
                : ''}
            </Typography>

            <Typography className={classes.title} gutterBottom variant={'subtitle1'} color={'textSecondary'}>
              {memberData ? (
                <>
                  <FontAwesomeIcon icon={faEnvelope as IconProp} className={classes.icon} />
                  {memberData.username}
                </>
              ) : (
                ''
              )}
            </Typography>

            <Typography className={classes.title} gutterBottom variant={'subtitle1'} color={'textSecondary'}>
              {memberData?.phone_number ? (
                <>
                  <FontAwesomeIcon icon={faPhone as IconProp} className={classes.icon} />
                  {memberData.phone_number}
                </>
              ) : (
                ''
              )}
            </Typography>
          </Box>
          <Tags
            tags={userGroups.map((group) => ({
              label: group.name,
              color: theme.palette.background.secondaryGradient,
            }))}
            limit={4}
          />
        </Card>
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Card>
            <LoadingContainer />
          </Card>
        </Grid>
      ) : memberData ? (
        <>
          <Grid item xs={12} lg={5} height={300}>
            <Card>
              <AwarenessScore score={memberData?.awareness_score || 0} loading={isLoading} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={7} height={300}>
            <Card>
              <EngagementChart id={_id ?? ''} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <Typography variant={'subtitle2'}>{t('userProfile.exposureSegments')}</Typography>
              <Box height={300}>
                <ExposureChart id={_id ?? ''} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <UserCampaignsTable userId={_id ?? ''} />
            </Card>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Card>
            <NoResultsContainer text={'userProfile.noData'} />
          </Card>
        </Grid>
      )}
    </LayoutPageContent>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    icon: { marginRight: theme.spacing(1) },
  })
)
export default UserProfile
