import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

//need to be replace with new-enpoint!
const deleteGroup = async (_id: string) => {
  const url = `${API_URL}/groups/delete/${_id}`
  //need to be replace delete instead of post - new-enpoint!
  const { data } = await axios.post(url, {}, { withCredentials: true })

  return data
}

export const useDeleteGroup = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['groups'],
      })
    },
  })
}
