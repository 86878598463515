import axios from 'axios'
import { API_URL } from '@/api/constants'
import { DefinedInitialDataOptions, keepPreviousData, useQuery } from '@tanstack/react-query'
import { CampaignUser } from '@/types/campaigns'
import { PagedResult, PagedQuery } from '@/types/common'

export type CampaignUsersQuery = PagedQuery & {
  id: string | undefined
}

const getCampaignUsers = async (id: string | undefined, limit: number, skip: number) => {
  const { data } = await axios.get(`${API_URL}/campaigns/${id}/users/`, {
    params: { ...(skip && { skip }), ...(limit && { limit }) },
    withCredentials: true,
  })

  return data
}

export const useCampaignUsers = (
  { id, limit = 10, skip = 0 }: CampaignUsersQuery,
  options?: Partial<DefinedInitialDataOptions<PagedResult<CampaignUser>>>
) => {
  return useQuery<PagedResult<CampaignUser>>({
    queryKey: ['campaign', id, limit, skip],
    queryFn: () => getCampaignUsers(id, limit, skip),
    enabled: !!id,
    placeholderData: keepPreviousData,
    ...options,
  })
}
