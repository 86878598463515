export const MuiTab = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      minHeight: 36.5,
      minWidth: 80,
      padding: '2px 12px 0px',
      '@media(min-width: 600px)': {
        minWidth: 80,
      },
    },
    textColorPrimary: {
      '& .Mui-selected': {
        color: '#181F2A',
        transition: 'color .5s',
      },
    },
  },
}

export const MuiTabs = {
  styleOverrides: {
    root: {
      backgroundColor: '#fff',
      borderRadius: 10,
      minHeight: 36.5,
      color: '#181F2A',
      '& .Mui-selected': {
        color: '#181F2A',
        transition: 'color .5s',
      },
    },
    flexContainer: {
      display: 'inline-flex',
      position: 'relative',
      zIndex: 1,
    },
    scroller: {
      padding: '0 8px',
    },
    indicator: {
      bottom: 0,
      right: 3,
      left: 3,

      height: '100%',
      background: 'none',
      minWidth: 80,
      position: 'absolute',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 3,
        borderRadius: 50,
        backgroundColor: '#000',
      },
    },
  },
}
