import { FC, useMemo } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochipAi, faTags, faPaintbrushPencil, faBoxOpen } from '@fortawesome/pro-light-svg-icons'

import { usePhishingSimulations } from '@/api/phishing-simulations/get'
import TagsFilterNew, { Tag } from '@/common/components/TagsFilter/TagsFilterNew'
import { theme } from '@/theme/theme'
import { styled } from '@mui/material'

type TagsFilterProps = {
  queryParams: any
  setQueryParams: (values: any) => void
}

const SimulationsTagsFilter: FC<TagsFilterProps> = ({ queryParams, setQueryParams }) => {
  const { data: phishingSimulationData } = usePhishingSimulations({ limit: 3000000 }) // As a general rule BE doesn't allow limit = 0

  const tags: Tag[] = useMemo(() => {
    if (!phishingSimulationData) return []
    const staticTags = [
      { value: true, label: 'Custom', propKey: 'custom', icon: <ChipIcon type="custom" /> },
      { value: true, label: 'Custom AI', propKey: 'ai_generated', icon: <ChipIcon type="ai" /> },
      { value: true, label: 'Packages Only', propKey: 'package_only', icon: <ChipIcon type="package" /> },
    ]

    const dynamicTags = phishingSimulationData.results
      .flatMap((simulation) => simulation.tags)
      .map((tag) => ({ value: tag, label: tag, propKey: 'tags', icon: <ChipIcon type="tag" /> }))

    return [...staticTags, ...dynamicTags]
  }, [phishingSimulationData])

  return <TagsFilterNew tags={tags} queryParams={queryParams} updateQueryParam={setQueryParams} />
}

const ChipIcon: FC<{ type: string }> = ({ type }) => {
  switch (type) {
    case 'tag':
      return <StyledIcon icon={faTags as IconProp} />
    case 'ai':
      return <StyledIcon icon={faMicrochipAi as IconProp} />
    case 'custom':
      return <StyledIcon icon={faPaintbrushPencil as IconProp} />
    case 'package':
      return <StyledIcon icon={faBoxOpen as IconProp} />
    default:
      return null
  }
}

const StyledIcon = styled(FontAwesomeIcon)({
  marginLeft: theme.spacing(1),
})

export default SimulationsTagsFilter
