import { Avatar, Box, Chip, IconProps, styled, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import { theme } from '@/theme/theme'
import { Admin } from '@/types/admins'
import CardHeader from '@/common/components/Card/CardHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonToPortal, faRightFromBracket, faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const AdminProfileHeader: FC<{ adminData: Admin }> = ({ adminData }) => {
  const { t } = useTranslation()
  return (
    <>
      <CardHeader>
        <>
          <strong>Admin Profile Summary</strong> - {adminData.full_name}
        </>
      </CardHeader>
      <Box display="flex" justifyContent="stretch" marginTop={2}>
        <IconElement
          Icon={faRightFromBracket}
          title={t(`settings.admins.profile.lastSignIn`)}
          subtitle={dayjs(adminData.last_sign_in).format(US_DATE_WITH_MONTH_NAME)}
        />
        <IconElement
          Icon={faPersonToPortal}
          title={t(`settings.admins.profile.joinedOn`)}
          subtitle={dayjs(adminData.joined_date).format(US_DATE_WITH_MONTH_NAME)}
        />
        <IconElement Icon={faEnvelope} title={t(`settings.admins.profile.email`)} subtitle={adminData.username} />
        <IconElement Icon={faPhone} title={t(`settings.admins.profile.phone`)} subtitle={adminData.phone_number} />
      </Box>
    </>
  )
}

type IconElementProps = {
  Icon: IconProp
  title: string
  subtitle: string
}
const IconElement: FC<IconElementProps> = ({ Icon, title, subtitle }) => {
  return (
    <Box
      // backgroundColor={theme.palette.cyan[100]}
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      p={2}>
      <FontAwesomeIcon icon={Icon} fontSize={36} />
      <Typography marginTop={2} fontSize={18} fontWeight={theme.typography.fontWeightSemiBold}>
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
    </Box>
  )
}

export const StyledAvatar = styled(Avatar)(() => ({
  background: theme.palette.cyan[500],
  width: '72px',
  height: '72px',
}))

export default AdminProfileHeader
