import {
  faBadgeCheck,
  faCopy,
  faExclamationSquare,
  faPaperPlane,
  faRotateRight,
  faTrash,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Divider, IconButton, keyframes, Paper, styled, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { useClientDetails } from '@/api/client/client'
import { DOMAIN_REGEX, DOMAIN_VERIFY_REFRESH_DEBOUNCE, US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import { useDomainCreate } from '@/api/domains/create'
import { useDeleteDomain } from '@/api/domains/delete'
import { useDomains } from '@/api/domains/get'
import { useVerifyTXTRecord } from '@/api/domains/verifyTXTRecord'
import { getErrorMessage } from '@/api/utils/get-error'
import removeImg from '@/assets/images/popup/delete.svg'
import Table, { TalbeColumn } from '@/common/components/Table'
import useQueryParams from '@/common/hooks/useQueryParams'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { SortOrder } from '@/types/common'
import { Domain, DomainListQuery, DomainQuerySort } from '@/types/domains'
import TextField from '../Inputs/TextFieldV2'
import Popup from '../Popup'
import TruncatedText from '../TruncatedText/TruncatedText'

const INITIAL_QUERY_FILTERS = {
  sort_by: DomainQuerySort.domain,
  sort_order: SortOrder.asc,
  status: [],
  skip: 0,
  limit: 5,
}

const DomainsTable: FC = () => {
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()

  const [deleteDomainModalOpen, setDeleteDomainModalOpen] = useState(false)
  const [domainToDelete, setDomainToDelete] = useState<Domain | null>(null)

  const { data: clientDetails } = useClientDetails()

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const { data: queryData, isFetching } = useDomains(queryParams as DomainListQuery)
  const { results: domains, total } = queryData || { total: 0, results: [] }

  const { mutateAsync: createDomain } = useDomainCreate()
  const { mutateAsync: deleteDomain } = useDeleteDomain()
  const { mutateAsync: verifyTXTRecord } = useVerifyTXTRecord()

  const handleAddDomain = async (domain: string) => {
    try {
      await createDomain(domain)
      successToast(t('domainsTableComponent.addDomainSuccessful', { domain }))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('domainsTableComponent.errors.failedToAddDomain', { domain }))
    }
  }

  const handleRefreshDomain = async (domain_id: string) => {
    try {
      await verifyTXTRecord(domain_id)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }

  const handleDeleteDomainModalOpen = (domain: Domain) => {
    setDomainToDelete(domain)
    setDeleteDomainModalOpen(true)
  }

  const handleDeleteDomainModalClose = () => {
    setDomainToDelete(null)
    setDeleteDomainModalOpen(false)
  }

  const handleRemoveDomain = async () => {
    try {
      await deleteDomain(domainToDelete?._id || '')
      handleDeleteDomainModalClose()
      successToast(t('domainsTableComponent.deleteSuccessToast'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }

  const domainValidationSchema = Yup.object().shape({
    newDomain: Yup.string()
      .matches(DOMAIN_REGEX, t('domainsTableComponent.validations.invalid'))
      .test(
        'not-existing',
        t('domainsTableComponent.validations.exist'),
        (value: string) => !domains.some((item) => item.domain === value)
      ),
  })

  const AddDomainFieldComponent = () => (
    <Box padding={theme.spacing(1.5, 2)} sx={{ width: '50%', minWidth: '320px' }}>
      <Formik
        onSubmit={(values, { resetForm }) => {
          !!values.newDomain && handleAddDomain(values.newDomain)
          resetForm()
        }}
        initialValues={{ newDomain: '' }}
        validationSchema={domainValidationSchema}>
        {({ isSubmitting, values }) => (
          <Form>
            <TextField
              name="newDomain"
              label={t('domainsTableComponent.addDomain')}
              placeholder={t('domainsTableComponent.addDomainPlaceholder')}
              InputProps={{
                endAdornment: (
                  <Box display={'flex'} alignItems="center" gap={1}>
                    <Divider
                      sx={{
                        minHeight: 42,
                        alignSelf: 'center',
                        borderColor: theme.palette.grey[400],
                      }}
                      orientation="vertical"
                    />
                    <Button
                      type="submit"
                      disabled={!values.newDomain || isSubmitting}
                      endIcon={<FontAwesomeIcon icon={faPaperPlane} color={theme.palette.blueGray[900]} />}>
                      {t('domainsTableComponent.add')}
                    </Button>
                  </Box>
                ),
              }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  )

  const tableColumns: TalbeColumn[] = [
    {
      id: 'domain',
      label: t('domainsTableComponent.domainName'),
      sortable: true,
      component: (row: Domain) => <TruncatedText text={row.domain} maxLength={28} />,
    },
    {
      id: 'verify_token',
      label: t('domainsTableComponent.record'),
      component: (row: Domain) => <TokenCopyCellComponent verify_token={row.verify_token} />,
    },
    {
      id: 'created_at',
      label: t('domainsTableComponent.addedDate'),
      sortable: true,
      format: (row: Domain) => (row.created_at ? dayjs.utc(row.created_at).format(US_DATE_WITH_MONTH_NAME) : '-'),
    },
    {
      id: 'verified',
      label: t('domainsTableComponent.verified'),
      sortable: true,
      component: (row: Domain) => <VerifiedCellComponent verified={row.verified} domain_id={row._id} />,
    },
    {
      id: 'actions',
      label: '',
      sortable: false,
      component: (row: Domain) => (
        <ActionsCellComponent
          row={row}
          refreshDomain={handleRefreshDomain}
          deleteDomainConfirm={handleDeleteDomainModalOpen}
        />
      ),
    },
  ]

  return (
    <Paper sx={{ overflowX: 'auto', padding: 0, margin: 0 }}>
      <Popup
        open={deleteDomainModalOpen}
        onClose={handleDeleteDomainModalClose}
        onConfirm={handleRemoveDomain}
        buttonLabels={{
          confirm: t('domainsTableComponent.delete'),
          cancel: t('domainsTableComponent.cancel'),
        }}
        icon={removeImg}>
        <h4>{t('domainsTableComponent.deleteTitle')}</h4>
        <p>
          {domainToDelete?.has_members && !clientDetails?.allow_unverified_domains
            ? t('domainsTableComponent.deleteDescription')
            : domainToDelete?.has_members && clientDetails?.allow_unverified_domains
            ? t('domainsTableComponent.deleteDescriptionVerified')
            : ''}
        </p>
        <p>{t('domainsTableComponent.deleteSubtitle')}</p>
      </Popup>
      <AddDomainFieldComponent />
      <Divider />
      <Table
        loading={isFetching}
        data={domains}
        total={total}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
        columns={tableColumns}
      />
    </Paper>
  )
}

const VerifiedCellComponent: FC<{
  verified: boolean
  domain_id: string
}> = ({ verified, domain_id }) => (
  <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
    {verified ? (
      <>
        <FontAwesomeIcon icon={faBadgeCheck} color={theme.palette.green[400]} fontSize={20} />
        Yes
      </>
    ) : (
      <>
        <FontAwesomeIcon icon={faExclamationSquare} color={theme.palette.red[900]} fontSize={20} />
        No
      </>
    )}
  </Box>
)

const TokenCopyCellComponent: FC<{ verify_token: string }> = ({ verify_token }) => {
  //TODO Add the prefix on the BE and remove from here
  verify_token = `CY=${verify_token}`

  const { t } = useTranslation()
  const { successToast } = useToast()

  const copyToken = () => {
    navigator.clipboard.writeText(verify_token)
    successToast(t('domainsTableComponent.copiedSuccessfully'))
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography fontSize={14}>{verify_token}</Typography>
      <IconButton onClick={copyToken}>
        <Tooltip title={t('domainsTableComponent.tooltips.copy')} placement="top-start">
          <FontAwesomeIcon icon={faCopy} fontSize={16} color={theme.palette.black} />
        </Tooltip>
      </IconButton>
    </Box>
  )
}

type ActionsCellComponentProps = {
  row: Domain
  refreshDomain: (domain_id: string) => void
  deleteDomainConfirm: (domain: Domain) => void
}

const ActionsCellComponent: FC<ActionsCellComponentProps> = ({ row, refreshDomain, deleteDomainConfirm }) => {
  const { t } = useTranslation()
  const [isRefreshable, setIsRefreshable] = useState(true)

  useEffect(() => {
    if (!isRefreshable)
      setTimeout(() => {
        setIsRefreshable(true)
      }, DOMAIN_VERIFY_REFRESH_DEBOUNCE)
  }, [isRefreshable])

  return (
    <Box display="flex" gap={2} justifyContent="flex-end">
      <IconButton
        onClick={() => {
          refreshDomain(row._id)
          setIsRefreshable(false)
        }}
        disabled={!isRefreshable}>
        <Tooltip title={t('domainsTableComponent.tooltips.refresh')} placement="top-start">
          <RotatingIcon icon={faRotateRight} fontSize={16} color={theme.palette.black} shouldRotate={!isRefreshable} />
        </Tooltip>
      </IconButton>

      <IconButton
        onClick={() => {
          deleteDomainConfirm(row)
        }}>
        <Tooltip title={t('domainsTableComponent.tooltips.delete')} placement="top-start">
          <FontAwesomeIcon icon={faTrash} fontSize={18} color={theme.palette.blueGray[900]} />
        </Tooltip>
      </IconButton>
    </Box>
  )
}

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const RotatingIcon = styled(FontAwesomeIcon)<{ shouldRotate: boolean }>(({ shouldRotate }) => ({
  animation: shouldRotate ? `${rotateAnimation} 1s linear infinite` : 'none',
  opacity: shouldRotate ? '50%' : '100%',
}))
export default DomainsTable
