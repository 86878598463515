import React, { createContext, useState, FC, ReactNode } from 'react'

interface ContentLibraryState {
  name: string
  languages: string[]
  tags: string[]
  difficulties?: string[]
  vectors?: string[]
  ai_generated?: boolean | null
  is_public?: boolean | null
  package_only?: boolean | null
}

interface ContentLibraryContextProps {
  queryFilters: ContentLibraryState
  setQueryFilters: React.Dispatch<React.SetStateAction<ContentLibraryState>>
}

export const ContentLibraryContext = createContext<ContentLibraryContextProps | undefined>(undefined)

interface ContentLibraryProviderProps {
  children: ReactNode
}

export const ContentLibraryProvider: FC<ContentLibraryProviderProps> = ({ children }) => {
  const [queryFilters, setQueryFilters] = useState<ContentLibraryState>({
    name: '',
    languages: [],
    tags: [],
    difficulties: [],
    vectors: [],
  })

  return (
    <ContentLibraryContext.Provider value={{ queryFilters, setQueryFilters }}>
      {children}
    </ContentLibraryContext.Provider>
  )
}
