import axios from 'axios'
import { API_URL } from '../constants'
import { ServerError } from '../types'
import { paramsSerializer } from '../utils/serialize-array'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { PagedResult } from '@/types/common'

export type ReportObjectMin = {
  _id: string
  reported_by: string
  date_reported: number
  is_phishing: boolean | null
  subject: string
  type: string
}

export type ReportObjectFull = ReportObjectMin & {
  sender: string
  recipients: string[]
  overall_score: number
  date_recieved: number
  raw_html: string
  headers: string[]
  is_simulated: boolean
  findings: {
    [finding: string]: {
      title: string
      description: string
    }
  }
  links: {
    [domain: string]: number
  }
  attachments: string[]
  client_id: string
}

export type ReportsResult = {
  results: ReportObjectMin[]
  total: number
}

export type ReportsQuery = {
  skip: number
  limit: number
  start_date: number | null
  end_date: number | null
  sort_by: string
  sort_order: 'asc' | 'desc' | ''
}

const getAllReports = async (params: ReportsQuery) => {
  const url = `${API_URL}/reports/`
  const { data } = await axios.get(url, {
    params,
    paramsSerializer: paramsSerializer,
    withCredentials: true,
  })
  return data
}

export const useAllReports = (query: ReportsQuery) => {
  return useQuery<PagedResult<ReportObjectMin>>({
    queryKey: ['reports', { ...query }],
    queryFn: () => getAllReports(query),
  })
}

const getReportById = async (_id: string) => {
  if (_id) {
    const url = `${API_URL}/reports/${_id}`
    const { data } = await axios.get(url, { withCredentials: true })
    return data
  } else {
    return undefined
  }
}

export const useReport = ({ _id }: { _id: string }) => {
  return useQuery<ReportObjectFull, ServerError>({
    queryKey: ['reports', _id],
    queryFn: () => getReportById(_id),
  })
}

type ChangeReportVariables = {
  is_phishing: boolean | null
  _id: string
}

export const changeReportStatus = async ({ is_phishing, _id }: ChangeReportVariables) => {
  const url = `${API_URL}/reports/status/${_id}`
  const { data } = await axios.post(url, { is_phishing }, { withCredentials: true })

  return data.data
}

export const useChangeReportStatus = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: changeReportStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['reports'],
      })
    },
  })
}
