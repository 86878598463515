import { Box, Button, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { useUpdateClient } from '@/api/client/update-client'
import TextField from '@/common/components/Inputs/TextFieldV2'
import UploadImageBox from '@/common/components/UploadImageBox/UploadImageBox'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import CountrySelect from '@/common/components/Inputs/CountrySelect'
import { useOrganizationIndustries } from '@/api/organizations/getIndustries'
import Select from '@/common/components/Inputs/Select'

type OrganizationSettingsProps = {
  name?: string
  country?: string
  logo?: string
  industry?: string
}

const OrganizationSettings: FC<OrganizationSettingsProps> = ({ name, country, industry, logo }) => {
  const { t } = useTranslation()
  const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClient()
  const { data: industriesyData } = useOrganizationIndustries()
  const { results: industriesData } = industriesyData || { results: [] }

  const initialClientValues = {
    name: name || '',
    origin_country: country || '',
    industry: industry || '',
  }
  const { successToast, errorToast } = useToast()

  const industries = useMemo(
    () => industriesData?.map((i) => ({ value: i.slug, label: t(`industries.${i.slug}`) })),
    [industriesData, t]
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2).required(t('settings.configurationTab.organizationSettings.organiztaionNameRequired')),
    industry: Yup.string().required('Indusrty is required'),
  })

  async function handleOnFinishImageUpload(url: string) {
    await updateClient({ logo: url })
  }

  const handleSubmit = async (values: typeof initialClientValues) => {
    try {
      await updateClient({ ...values })
      successToast(t('settings.configurationTab.organizationSettings.organizationUpdated'))
    } catch (e) {
      errorToast(t('settings.configurationTab.organizationSettings.errors.organizationUpdateFailed'))
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialClientValues} validationSchema={validationSchema}>
      {({ isSubmitting, dirty, isValid }) => (
        <Form>
          <Box display="flex" flexDirection={'column'} gap={3}>
            <Typography fontWeight={theme.typography.fontWeightSemiBold} fontSize={18}>
              {t('settings.configurationTab.organizationSettings.organizationSettings')}
            </Typography>
            <Box display="flex" height={'184px'}>
              <UploadImageBox image={logo} handleOnFinishImageUpload={handleOnFinishImageUpload} />
            </Box>
            <TextField
              label={t('settings.configurationTab.organizationSettings.organiztaionName')}
              name="name"
              required
            />
            <CountrySelect name="origin_country" label={t('onboarding.step1.originCountry')} />
            <Select name="industry" label={t('onboarding.step1.industry')} options={industries} requiredLabel />
            <Button type="submit" disabled={!dirty || !isValid || isSubmitting || isUpdatingClient} variant="contained">
              {t('settings.configurationTab.organizationSettings.saveCahnge')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default OrganizationSettings
