import { MemberListItem, MembersListQuery } from '@/types/members'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { PagedResult } from '@/types/common'
import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'

const getMembers = async (params: MembersListQuery) => {
  const url = `${API_URL}/members/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params,
    paramsSerializer,
  })

  return data
}

export const useMembers = (query: MembersListQuery) => {
  return useQuery<PagedResult<MemberListItem>>({
    queryKey: ['members', query],
    queryFn: () => getMembers(query),
    placeholderData: keepPreviousData,
  })
}
