import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteGroup } from '@/api/group/delete'
import useToast from '@/common/hooks/useToast'
import DeletionModal from '../DeletionModal'
import GroupsTable from './GroupsTable'
import { Box } from '@mui/material'

type GroupsTabProps = {
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const GroupsTab = ({ queryParams, updateQueryParam }: GroupsTabProps) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteGroup } = useDeleteGroup()
  const { successToast, errorToast } = useToast()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [groupForDeletion, setGroupForDeletion] = useState<string>('')

  async function handleGroupDeleteAlert(groupId: string) {
    setGroupForDeletion(groupId)
    setDeleteModalIsOpen(true)
  }

  async function handleCloseGroupDelete() {
    setDeleteModalIsOpen(false)
    setGroupForDeletion('')
  }

  async function handleDeleteGroup() {
    try {
      await deleteGroup(groupForDeletion)
      successToast(t('users.groupsTable.groupDeleted'))
      setDeleteModalIsOpen(false)
    } catch (e) {
      errorToast(t('users.groupsTable.errors.faliedDeleteGroup'))
    }
  }

  return (
    <Box>
      <DeletionModal
        confirmDeleteOpen={deleteModalIsOpen}
        handleCancelDelete={handleCloseGroupDelete}
        deleteIntegrationHandler={handleDeleteGroup}
        title={t('users.groupsTable.deleteGroup')}
        description={t('users.groupsTable.deleteGroupAlert')}
      />
      <GroupsTable
        handleDeleteGroup={handleGroupDeleteAlert}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
      />
    </Box>
  )
}

export default GroupsTab
