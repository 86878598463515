import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { NewOfficeRequest } from '@/types/offices'

const createNewOffice = async (params: NewOfficeRequest) => {
  const url = `${API_URL}/offices/`
  const { data } = await axios.post(url, params, { withCredentials: true })

  return data
}

export const useCreateNewOffice = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createNewOffice,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['offices'],
      })
      await queryClient.invalidateQueries({ queryKey: ['organizationOnbordingStatus'] })
    },
  })
}
