import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { ServerError } from '../types'

type DeleteMemberRequest = {
  id: string
}

type DeleteMemberResponse = {
  message: boolean
}

const deleteMember = async ({ id }: DeleteMemberRequest) => {
  const url = `${API_URL}/members/${id}/`
  const { data } = await axios.delete<DeleteMemberResponse>(url, {
    withCredentials: true,
  })

  return data
}

const useDeleteMember = () => {
  const queryClient = useQueryClient()

  return useMutation<DeleteMemberResponse, ServerError, DeleteMemberRequest>({
    mutationFn: deleteMember,
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['members'] }),
        queryClient.invalidateQueries({ queryKey: ['groups'] }),
        queryClient.invalidateQueries({ queryKey: ['offices'] }),
      ])
    },
    ...{
      throwOnError: true,
    },
  })
}

export default useDeleteMember
