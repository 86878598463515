import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useSwitchClientV5 } from '@/api/profile/switch-client'
import { getErrorMessage } from '@/api/utils/get-error'
import noPermissionImg from '@/assets/images/account-center/no-permissions.svg'
import switchAccountImg from '@/assets/images/account-center/switch-account.svg'
import useToast from '@/common/hooks/useToast'
import Popup from '../Popup'
import { OrganizationCardData } from '@/pages/AccountCenter'

type ClientSwitchPopupProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  organization: OrganizationCardData
}

const ClientSwitchPopup: FC<ClientSwitchPopupProps> = ({ open, setOpen, organization }) => {
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const { mutateAsync: switchClient } = useSwitchClientV5()

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogin = async () => {
    try {
      await switchClient(organization.id)
      window.location.href = '/'
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast('Login error ' + errorMessage || '')
    }
  }
  if (organization?.is_admin_authorized)
    return (
      <Popup
        open={open}
        onClose={handleClose}
        onConfirm={handleLogin}
        buttonLabels={{
          confirm: t('accountCenter.clientSwtichPopup.submit'),
          cancel: t('accountCenter.clientSwtichPopup.cancel'),
        }}
        icon={switchAccountImg}>
        <h4>{t('accountCenter.clientSwtichPopup.title')}</h4>
        <p>{t('accountCenter.clientSwtichPopup.message', { organizationName: organization.name })}</p>
      </Popup>
    )
  return (
    <Popup
      open={open}
      onClose={handleClose}
      buttonLabels={{
        cancel: t('accountCenter.clientSwtichPopup.noPermissionsCancel'),
      }}
      icon={noPermissionImg}>
      <h4>{t('accountCenter.clientSwtichPopup.noPermissionsTitle')}</h4>
      <span>{t('accountCenter.clientSwtichPopup.noPermissionsMessage')}</span>
    </Popup>
  )
}

export default ClientSwitchPopup
