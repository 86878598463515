import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { EditMembersParams, Member } from '@/types/members'

export const editMember = async ({ _id, params }: EditMembersParams): Promise<Member> => {
  const url = `${API_URL}/members/${_id}/`
  const { data } = await axios.patch(url, params, { withCredentials: true })
  return data
}

export const useEditMember = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editMember,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['members'],
        }),
        queryClient.invalidateQueries({ queryKey: ['groups'] }),
        queryClient.invalidateQueries({ queryKey: ['offices'] }),
      ])
    },
  })
}
