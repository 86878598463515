import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'
import { EditOfficeRequest, Office } from '@/types/offices'

const editOffice = async (editOfficePayload: EditOfficeRequest): Promise<Office> => {
  const url = `${API_URL}/offices/${editOfficePayload._id}`
  console.log(editOfficePayload)
  const { data } = await axios.patch(url, editOfficePayload, { withCredentials: true })

  return data.data
}

export const useEditOffice = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editOffice,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['offices'],
      })
    },
  })
}
