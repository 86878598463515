import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Member, NewMemberParams } from '@/types/members'

export const createNewMember = async (params: NewMemberParams): Promise<Member> => {
  const url = `${API_URL}/members/`
  const { data } = await axios.post(url, params, { withCredentials: true })
  return data
}

export const useCreateNewMember = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createNewMember,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['members'],
        }),
        queryClient.invalidateQueries({ queryKey: ['organizationOnbordingStatus'] }),
        queryClient.invalidateQueries({ queryKey: ['groups'] }),
        queryClient.invalidateQueries({ queryKey: ['offices'] }),
      ])
    },
  })
}
