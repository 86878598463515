import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'
import { EditGroupRequest, Group } from '@/types/groups'

//need to be replace with new-enpoint!
const editGroup = async (editGroupPayload: EditGroupRequest): Promise<Group> => {
  const url = `${API_URL}/groups/edit/${editGroupPayload._id}`
  const { data } = await axios.post(url, editGroupPayload, { withCredentials: true })

  return data.data
}

export const useEditGroup = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: editGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['groups'],
      })
    },
  })
}
