import { ReactElement } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material/'
import { useTranslation } from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

type AlertProps = {
  buttonLabel?: string
  dialogTitle?: string
  dialogText?: string | string[]
  dialogContent?: string | ReactElement
  onConfirm?: () => void
  handleClose: () => void
  open: boolean
  cancelButton?: boolean
  confirmButton?: boolean
  closeOnClick?: boolean
}

const Alert = ({
  buttonLabel,
  dialogTitle,
  dialogText,
  dialogContent,
  onConfirm = () => {},
  open,
  handleClose,
  cancelButton = false,
  confirmButton = true,
  closeOnClick = true,
}: AlertProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleSubmit = () => {
    onConfirm()
    handleClose()
  }
  return (
    <Dialog
      scroll={'paper'}
      open={open}
      maxWidth={'sm'}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
      onClick={() => {
        closeOnClick && handleClose()
      }}>
      <div style={{ position: 'relative' }}>
        {dialogTitle && <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>}
        <DialogContent>
          {!dialogText ? null : typeof dialogText === 'string' ? (
            <DialogContentText>{dialogText}</DialogContentText>
          ) : (
            <DialogContentText>
              {dialogText.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </DialogContentText>
          )}
          {dialogContent && dialogContent}
        </DialogContent>
        <DialogActions>
          {cancelButton && (
            <Button onClick={handleClose} variant={'text'} autoFocus>
              {t('alertComponent.cancel')}
            </Button>
          )}
          {confirmButton && (
            <Button onClick={handleSubmit} variant={'contained'}>
              {buttonLabel ? buttonLabel : t('alertComponent.ok')}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      border: 'none',
    },
    dialogTitle: {
      background: theme.palette.blueGray[900],
      color: theme.palette.cyan[500],
      marginBottom: theme.spacing(2),
    },
  })
)

export default Alert
