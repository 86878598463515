import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalculatorSimple, faHouse } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Divider, Typography, styled } from '@mui/material'
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useClientDetails } from '@/api/client/client'
import { useOrganizations } from '@/api/organizations/get'
import CywarenessBackground from '@/assets/images/logo-light-blue.svg'
import AddModal from '@/common/components/AccountCenter/AddModal'
import ArchivePopup from '@/common/components/AccountCenter/ArchivePopup'
import ClientSwitchPopup from '@/common/components/AccountCenter/ClientSwitchPopup'
import EditModal from '@/common/components/AccountCenter/EditModal'
import TreeChart from '@/common/components/AccountCenter/TreeChart'
import { theme } from '@/theme/theme'
import { OrganizationAccountType, OrganizationListItem } from '@/types/organizations'

export type OrganizationCardData = OrganizationListItem & {
  id: string
  parentId: string
  allow_support_admin_access?: boolean
  _highlighted: boolean
  _expanded: boolean
}

export type ModalProps = {
  open: boolean
  modalType: ModalType
  setOpen: Dispatch<SetStateAction<boolean>>
  organization: OrganizationCardData
  focusNode?: (value: OrganizationListItem | null, collapse?: boolean, highlighted?: boolean) => void
}

export enum ModalType {
  default,
  delete,
  edit,
  add,
  invite,
  clientSwitch,
}

const AccountCenter: FC = () => {
  const { data } = useOrganizations()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: client } = useClientDetails()

  const [relatedOrganization, setRelatedOrganization] = useState<OrganizationCardData>({} as OrganizationCardData)
  const [modalType, setModalType] = useState<number>(ModalType.default)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const treeChartData: OrganizationCardData[] = useMemo(() => {
    if (!data?.length) return []
    const fakeRoot = {
      id: 'fake_root',
      name: 'Account Center',
      parentId: null,
    }

    const organizations = data.map((organization) => ({
      ...organization,
      id: organization._id,
      parentId: organization.parent_id === null ? 'fake_root' : organization.parent_id,
    }))

    return [fakeRoot, ...organizations]
  }, [data]) as OrganizationCardData[]

  return (
    <StyledRootBox>
      <AccountCenterModal
        organization={relatedOrganization}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        modalType={modalType}
      />
      <StyledHeader>
        <Box display="flex" justifyContent="flex-end" gap={theme.spacing(1)} padding={theme.spacing(1.5, 1, 2.5)}>
          {(client?.type === OrganizationAccountType.direct_reseller ||
            client?.type === OrganizationAccountType.distributor) && (
            <Button
              variant="outlined"
              onClick={() => {
                navigateTo('/account-center/pricing-calculator')
              }}
              endIcon={<FontAwesomeIcon icon={faCalculatorSimple as IconProp} />}>
              {t('accountCenter.pricingCalculator')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigateTo('/')
            }}
            endIcon={<FontAwesomeIcon icon={faHouse as IconProp} />}>
            {t('accountCenter.backToDashboard')}
          </Button>
        </Box>
        <Divider color={theme.palette.grey[400]}></Divider>
        <Typography fontWeight={theme.typography.fontWeightBold} paddingLeft={theme.spacing(2)}>
          {t('accountCenter.accountCenter')}
        </Typography>
      </StyledHeader>
      <TreeChart
        data={treeChartData}
        setModalType={setModalType}
        setRelatedOrganization={setRelatedOrganization}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledRootBox>
  )
}

const AccountCenterModal: FC<ModalProps> = ({ organization, open, setOpen, focusNode, modalType }) => {
  switch (modalType) {
    case ModalType.edit:
      return <EditModal organization={organization} open={open} setOpen={setOpen} />
    case ModalType.delete:
      return <ArchivePopup open={open} setOpen={setOpen} organization={organization} />
    case ModalType.add:
      return <AddModal organization={organization} open={open} setOpen={setOpen} focusNode={focusNode} />
    case ModalType.clientSwitch:
      return <ClientSwitchPopup organization={organization} open={open} setOpen={setOpen} />
  }
}
const StyledRootBox = styled(Box)(() => ({
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${CywarenessBackground})`,
  backgroundPosition: 'bottom right',
  backgroundRepeat: 'no-repeat',
}))

const StyledHeader = styled(Box)(() => ({
  width: '100%',
  height: '128px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}))

export default AccountCenter
