import { SIMULATION_TYPES } from '../state'

type PromptTemplateProps = {
  template: string
  inputVariables: string[]
}

const formatPrompt = (templateProps: PromptTemplateProps, variables: any): string => {
  let formatted = templateProps.template

  for (const variable of templateProps.inputVariables) {
    formatted = formatted.replace(`{${variable}}`, variables[variable])
  }

  return formatted.trim().replace(/(\n\s*\n)/g, '\n')
}

const humanEmailPromptTemplate: PromptTemplateProps = {
  template: `
    1. Act as an email content writer creating a personal message.
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. Keep the email body short (3-5 lines), focusing on the main message.
    5. {templatePersonalizations}
    6. Structure the email using HTML tables for layout to ensure full compatibility with both Gmail and Outlook.
    7. Handle images carefully:
       - Use **max-width** and **height: auto** for responsive images in non-Outlook clients.
       - Provide a **VML fallback** for Outlook to prevent image stretching or distortion.
       - Set **width** and **height** explicitly in the VML block, based on the image's natural aspect ratio.
       - Use conditional comments to prevent duplicate images in Outlook.
    8. Add a call-to-action link using href="{{link_click_url}}" and style it with inline CSS (e.g., color, font-weight).
    9. Use padding inside <td> elements instead of margins for layout consistency.
    10. Include a personalized signature (e.g., "Best regards, {{senderSignature}}").
    11. Avoid adding unnecessary borders or backticks.
    12. Optimize the email for both desktop and mobile applications.
    13. Never ask the recipient to reply to the email.
    14. Example email structure with logo handling:
      <table style="width: 100%; max-width: 600px; border-collapse: collapse; font-family: Arial, sans-serif; line-height: 1.6;">
        <tbody>
          <tr>
            <td style="padding: 20px; background-color: #ebebeb; text-align: center;">
              <!-- Outlook-specific VML image -->
              <!--[if mso]>
              <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:200px;height:120px;">
                <v:fill src="https://yourlogo.com/logo.png" type="frame"/>
                <v:textbox inset="0,0,0,0" style="mso-fit-shape-to-text:true;"></v:textbox>
              </v:rect>
              <![endif]-->

              <!-- Standard HTML image for non-Outlook clients -->
              <!--[if !mso]><!-- -->
              <img src="https://yourlogo.com/logo.png" alt="Company Logo" style="max-width: 200px; height: auto; display: block; margin: auto;">
              <!--<![endif]-->
            </td>
          </tr>

          <tr>
            <td style="padding: 20px; background-color: #FFFFFF;">
              <p>Hello {{first_name}},</p>
              <p>Your order has been shipped. Click <a href="{{link_click_url}}" style="color: #4D148C; text-decoration: none;">here</a> to track your package.</p>
              <p>Thank you for your purchase!</p>
            </td>
          </tr>

          <!-- Signature -->
          <tr>
            <td style="padding: 20px; background-color: #F7F7F7; text-align: center;">
              <p>Best regards,</p>
              <p>{{senderSignature}}</p>
            </td>
          </tr>
        </tbody>
      </table>
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations', 'senderSignature'],
}

const appEmailPromptNoDesignTemplate: PromptTemplateProps = {
  template: `
    1. Act as an email content writer creating a simple, text-only message.
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. {templatePersonalizations}
    5. Use HTML tables with inline CSS to ensure compatibility with both Gmail and Outlook.
    6. Handle images carefully:
       - Use **max-width** and **height: auto** for non-Outlook clients.
       - Add a **VML fallback** for Outlook with explicit dimensions to maintain the image's natural aspect ratio.
       - Use conditional comments to prevent duplicate images.
    7. Add a call-to-action link using href="{{link_click_url}}" with inline styling.
    8. Use padding inside <td> elements instead of margins for layout consistency.
    9. Avoid unnecessary headers, footers, or complex designs.
    10. Return a simple HTML structure optimized for desktop and mobile views.
    11. Never ask the recipient to reply to the email.
    12. Example structure with image handling:
      <table style="width: 100%; max-width: 600px; border-collapse: collapse; font-family: Arial, sans-serif;">
        <tbody>
          <tr>
            <td style="padding: 20px; background-color: #ebebeb; text-align: center;">
              <!-- Outlook-specific VML image -->
              <!--[if mso]>
              <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:150px;height:90px;">
                <v:fill src="https://yourlogo.com/logo.png" type="frame"/>
                <v:textbox inset="0,0,0,0" style="mso-fit-shape-to-text:true;"></v:textbox>
              </v:rect>
              <![endif]-->

              <!-- Standard HTML image for non-Outlook clients -->
              <!--[if !mso]><!-- -->
              <img src="https://yourlogo.com/logo.png" alt="Logo" style="max-width: 150px; height: auto; display: block; margin: auto;">
              <!--<![endif]-->
            </td>
          </tr>

          <tr>
            <td style="padding: 20px; background-color: #FFFFFF;">
              <p>Hello,</p>
              <p>Your subscription has been renewed. You can <a href="{{link_click_url}}" style="color: #007BFF; text-decoration: none;">view your account</a> for more details.</p>
            </td>
          </tr>
        </tbody>
      </table>
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations'],
}

const appEmailPromptDesignedTemplate: PromptTemplateProps = {
  template: `
    1. Act as an email content writer creating a branded message.
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. {templatePersonalizations}
    5. Use HTML tables for layout to ensure full compatibility with both Gmail and Outlook.
    6. Handle images correctly:
       - Use **max-width** and **height: auto** in non-Outlook clients.
       - Use a **VML fallback** for Outlook with explicit dimensions to maintain aspect ratio.
       - Prevent duplicate logos by using conditional comments.
    7. Add a call-to-action link styled with inline CSS.
    8. Include padding inside <td> elements for spacing.
    9. Optimize the email for both desktop and mobile devices.
    10. Never ask the recipient to reply to the email.
    11. Example structure:
      <table style="width: 100%; max-width: 600px; border-collapse: collapse; font-family: Arial, sans-serif;">
        <tbody>
          <tr>
            <td style="padding: 20px; background-color: #4D148C; text-align: center;">
              <!-- VML and HTML logo example -->
              <img src="https://yourlogo.com/logo.png" alt="Company Logo" style="max-width: 200px; height: auto;">
            </td>
          </tr>

          <tr>
            <td style="padding: 20px; background-color: #FFFFFF;">
              <h2>Delivery Notification</h2>
              <p>Your package is on the way! Click <a href="{{link_click_url}}" style="color: #4D148C; text-decoration: none;">here</a> for details.</p>
            </td>
          </tr>

          <tr>
            <td style="padding: 20px; background-color: #4D148C; color: #FFFFFF; text-align: center;">
              <p>&copy; ${new Date().getFullYear()} Your Company. All rights reserved.</p>
            </td>
          </tr>
        </tbody>
      </table>
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations'],
}

const createHumanEmailPrompt = (
  senderName: string,
  senderPosition: string,
  emailBody: string,
  toneOfVoice: string,
  language: string,
  instructions?: string,
  emailSenderSignature?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `Compose a short email body (no subject please) from ${senderName}, ${senderPosition} about: ${emailBody}.`

  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `

  const senderSignature =
    emailSenderSignature !== ''
      ? `Use this image url ${emailSenderSignature} to add signature, Under the name add position.`
      : 'Sign email with your name and position'

  return formatPrompt(humanEmailPromptTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
    senderSignature,
  })
}

const createAppEmailPromptNoDesign = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `
    Create a minimalist, branded email body with the following details:
      Sent from: ${appName} 
      About: ${emailBody}. 
      ${
        customLogo
          ? `Use this image url ${customLogo} to add logo at the header, max width 100px, max height 60px (object-fit: contain), It is a Logo so keep it small`
          : ''
      }
  `
  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account.`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `

  return formatPrompt(appEmailPromptNoDesignTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
  })
}

const createAppEmailPromptDesigned = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string,
  iconTheme?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `
    Create a minimalist designed email content. 
      Sent from: ${appName} 
      About: ${emailBody}. 
      ${
        customLogo
          ? `Use this image url ${customLogo} to add logo at the header, max width 100px, max height 60px (object-fit: contain), It is a Logo so keep it small`
          : ''
      }
      ${iconTheme ? `2.1 Use this icon theme ${iconTheme} for the header and footer colors.` : ''}
  `

  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account for the email body.`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `
  return formatPrompt(appEmailPromptDesignedTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
  })
}

export const createEmailBodyPrompt = (
  selectedSimulationType: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions: string | null,
  senderName: string | null,
  senderPosition: string | null,
  vectorType: string,
  designedEmail: boolean,
  appName: string,
  emailSenderSignature: string | null,
  customLogo: string | null,
  iconTheme: string | null,
  personalizedTemplate: boolean | null
) => {
  if (selectedSimulationType === SIMULATION_TYPES.HUMAN && vectorType === 'email') {
    return createHumanEmailPrompt(
      senderName!,
      senderPosition!,
      emailBody,
      toneOfVoice,
      language,
      instructions!,
      emailSenderSignature!,
      personalizedTemplate!
    )
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === 'email' && !designedEmail) {
    return createAppEmailPromptNoDesign(
      appName,
      language,
      toneOfVoice,
      emailBody,
      instructions!,
      customLogo!,
      personalizedTemplate!
    )
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === 'email' && designedEmail) {
    return createAppEmailPromptDesigned(
      appName,
      language,
      toneOfVoice,
      emailBody,
      instructions!,
      customLogo!,
      iconTheme!,
      personalizedTemplate!
    )
  }

  return ''
}
