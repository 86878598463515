import { Card, Grid } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import ProtectedRoute from '@/Routes/ProtectedRoute'
import { useClientDetails } from '@/api/client/client'
import LayoutPageContent from '@/common/components/Layout/LayoutPageContent'
import PageHeader from '@/common/components/Layout/LayoutPageContent/PageHeader'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import { indexToTab, tabToIndex } from '@/common/utils/tab-utils'
import AdminsTable from './Admins/AdminsTable'
import InviteAdminModal from './Admins/InviteAdminModal'
import ConfigurationsTab from './Configurations'
import Integrations from './Integrations'
import Plugins from './Plugins/Plugins'
import AllowlistingTab from './AllowlistingTab'

const tabIndexMap = {
  '/settings/configurations': 0,
  '/settings/admins': 1,
  '/settings/plugins': 2,
  // '/settings/kyb': 3,
  '/settings/allowlisting': 3,
  '/settings/integrations': 4,
}

const Settings: FC = () => {
  const navigateTo = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { data: clientDetails, isLoading } = useClientDetails()
  const [currentTab, setCurrentTab] = useState(0)
  const [createAdminModalIsOpen, setCreateAdminModalIsOpen] = useState(false)

  const handleTabChange = useCallback(
    (e: any, tab: number) => {
      setCurrentTab(tab)
      navigateTo(indexToTab(tabIndexMap, tab))
    },
    [setCurrentTab, navigateTo]
  )

  useEffect(() => {
    if (pathname === '/settings') {
      navigateTo('/settings/configurations')
    } else if (tabToIndex(tabIndexMap, pathname) !== currentTab) {
      handleTabChange(null, tabToIndex(tabIndexMap, pathname))
    }
  }, [currentTab, handleTabChange, navigateTo, pathname])

  const tabs = [
    {
      label: 'settings.configurationTab.configurations',
    },
    {
      label: 'settings.admins.admins',
      component: <AdminsTable />,
    },
    { label: 'settings.plugins.plugins', component: <Plugins /> },
    {
      label: 'settings.whitelisting.whitelisting',
      component: <AllowlistingTab />,
    },
    {
      label: 'settings.integrations.integrations',
      component: <Integrations />,
    },
  ]

  return (
    <LayoutPageContent minWidth={800}>
      <PageHeader>
        {currentTab === 1 && <InviteAdminModal open={createAdminModalIsOpen} setOpen={setCreateAdminModalIsOpen} />}
      </PageHeader>
      <Grid item xs={12} xl={12}>
        <MainTabsWrapper>
          <MainTabs value={currentTab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <MainTab key={index} label={t(tab.label)} />
            ))}
          </MainTabs>
        </MainTabsWrapper>
        <MainTabPage>
          <Card>
            {isLoading ? (
              <LoadingContainer container={'paper'} />
            ) : (
              <Routes>
                <Route
                  path="configurations"
                  element={
                    <ProtectedRoute>
                      <ConfigurationsTab organiztion={clientDetails} />
                    </ProtectedRoute>
                  }
                />
                <Route path="admins" element={<AdminsTable />} />
                <Route
                  path="plugins"
                  element={
                    <ProtectedRoute>
                      <Plugins />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="allowlisting"
                  element={
                    <ProtectedRoute>
                      <AllowlistingTab />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="integrations"
                  element={
                    <ProtectedRoute>
                      <Integrations />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            )}
          </Card>
        </MainTabPage>
      </Grid>
    </LayoutPageContent>
  )
}

export default Settings
