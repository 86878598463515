import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useDisableAdmin } from '@/api/admins/disable-admin'
import { useEditAdminRole } from '@/api/admins/edit-role'
import { useEnableAdmin } from '@/api/admins/enable-admin'
import { useLogout } from '@/api/auth/logout'
import { PHONE_NUMNER_E164_REGEX } from '@/api/constants'
import { CurrentUser } from '@/api/profile/current-user'
import { Profile, useEditProfile } from '@/api/profile/edit'
import { getErrorMessage } from '@/api/utils/get-error'
import { ColunmsItem, FormButtons, FormSection, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import Checkbox from '@/common/components/Inputs/Checkbox'
import PhoneNumberField from '@/common/components/Inputs/PhoneNumberField'
import RadioGroup from '@/common/components/Inputs/RadioGroup'
import Select from '@/common/components/Inputs/Select'
import TextField, { FieldClasses } from '@/common/components/Inputs/TextFieldV2'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { Admin, Role, Status } from '@/types/admins'
import AdminStatusPopup from '../AdminStatusPopup'
import EmailConfirmModal from '../EmailConfirmModal'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'

const AdminProfileDetailsSection: FC<{ adminData: Admin; user: CurrentUser | undefined }> = ({ adminData, user }) => {
  const { successToast, errorToast } = useToast()
  const { mutateAsync: enableAdmin } = useEnableAdmin()
  const { mutateAsync: disableAdmin } = useDisableAdmin()
  const { t } = useTranslation()
  const [emailConfirmModalState, setEmailConfirmModalState] = useState<Partial<Profile> & { old_email: string }>() //Partial profule & {old_email:string}
  const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false)
  const { mutateAsync: editAdminRole } = useEditAdminRole()
  const [disableWarningModalOpen, setDisableWarningModalOpen] = useState(false)
  const navigateTo = useNavigate()
  const [logout] = useLogout()

  const { mutateAsync: editProfile } = useEditProfile()
  const isCurrnetUser = user?.id === adminData?._id

  const memberValidationSchema = Yup.object().shape(
    isCurrnetUser
      ? {
          full_name: Yup.string().required('Name is required'),
          username: Yup.string().email('Invalid email').required('Email is required'),
          phone_number: Yup.string()
            .matches(PHONE_NUMNER_E164_REGEX, 'Invalid phone number')
            .required('Phone number is required'),
        }
      : {}
  )
  const adminRolesValidationSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    receive_plugin_reports: Yup.boolean(),
    receive_campaign_reports: Yup.boolean(),
  })

  const adminDetailsInitilaVlaues = useMemo(
    () => ({
      full_name: adminData.full_name,
      username: adminData.username,
      phone_number: adminData.phone_number || '',
      state: adminData.status,
      role: adminData.role,
    }),
    [adminData]
  )

  const adminRolesInitilaVlaues = useMemo(
    () => ({
      role: adminData.role,
      receive_plugin_reports: adminData.receive_plugin_reports,
      receive_campaign_reports: adminData.receive_campaign_reports,
    }),
    [adminData]
  )

  const handleEmailChangeConfirm = async (profilePayload: any) => {
    if (!adminData?._id) return

    try {
      await editProfile(profilePayload)

      await logout()
      navigateTo('/sign-in')

      setEmailConfirmModalOpen(false)
      successToast(t(`settings.admins.profile.profileUpdated`))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t(`settings.admins.profile.failedToUpdate`))
    }
  }

  const handleEnableAdmin = useCallback(
    async (admin: Admin) => {
      try {
        await enableAdmin(admin._id)
      } catch (e) {
        errorToast(t('settings.admins.table.errors.failedToEnable'))
      }
    },
    [enableAdmin, successToast, errorToast, t]
  )

  const handleDisableAdmin = useCallback(async () => {
    try {
      await disableAdmin(adminData?._id || '')
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToDisable'))
    }
  }, [disableAdmin, successToast, errorToast, t])

  const handleSubmitAdminDetails = async (values: any) => {
    if (!adminData?._id) return

    if (isCurrnetUser) {
      if (values.state !== adminData.status) {
        if (values.state === Status.enabled) {
          handleEnableAdmin(adminData)
        } else {
          handleDisableAdmin()
        }
      }

      if (user && values.username && values.username !== user.username) {
        setEmailConfirmModalState({
          full_name: values.full_name,
          email: values.username,
          phone_number: values.phone_number,
          old_email: user.username || '',
        })
        setEmailConfirmModalOpen(true)

        return
      }
    }
    try {
      const adminPayload = {
        email: values.username,
        ...values,
      }

      if (isCurrnetUser) await editProfile(adminPayload as CurrentUser)

      successToast(t(`settings.admins.profile.profileUpdated`))
    } catch (e) {
      errorToast(t(`settings.admins.profile.failedToUpdate`))
    }
  }

  const handleSubmitAdminRols = async (values: any) => {
    if (!adminData?._id) return

    try {
      const rolePayout = {
        _id: adminData._id,
        role: values.role,
        receive_plugin_reports: values.receive_plugin_reports,
        receive_campaign_reports: values.receive_campaign_reports,
      }
      await editAdminRole(rolePayout)

      successToast(t(`Admin Roles updated`))
    } catch (e) {
      errorToast(t(`settings.admins.profile.failedToUpdate`))
    }
  }

  return (
    <>
      <TwoColunmsLayout>
        <ColunmsItem>
          <Formik
            onSubmit={handleSubmitAdminDetails}
            initialValues={adminDetailsInitilaVlaues}
            validationSchema={memberValidationSchema}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <FormSection>{t(`settings.admins.profile.adminDetails`)}</FormSection>
                <Box display="flex" flexDirection="column" gap={4} flex={1}>
                  <TextField
                    label="Name"
                    name="full_name"
                    disabled={!isCurrnetUser}
                    className={isCurrnetUser ? undefined : FieldClasses.locked}
                  />
                  <TextField
                    label="Email"
                    name="username"
                    disabled={!isCurrnetUser}
                    className={isCurrnetUser ? undefined : FieldClasses.locked}
                  />
                  <PhoneNumberField
                    name="phone_number"
                    label="Phone Number*"
                    disabled={!isCurrnetUser}
                    className={isCurrnetUser ? undefined : FieldClasses.locked}
                  />
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography fontWeight={theme.typography.fontWeightMedium} pb={2}>
                      {t(`settings.admins.profile.adminState`)}
                    </Typography>
                    <RadioGroup
                      name="state"
                      row
                      onChange={() => {
                        if (values.state === Status.enabled) {
                          setFieldValue('state', Status.disabled)
                          setDisableWarningModalOpen(true)
                        } else {
                          setFieldValue('state', Status.enabled)
                        }
                      }}
                      options={[
                        {
                          value: Status.enabled,
                          label: t(`settings.admins.profile.active`),
                        },
                        {
                          value: Status.disabled,
                          label: t(`settings.admins.profile.inactive`),
                        },
                      ]}
                    />
                  </Box>
                </Box>

                <FormButtons>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    {t(`settings.admins.profile.saveChanges`)}
                  </LoadingButton>
                </FormButtons>
              </Form>
            )}
          </Formik>
        </ColunmsItem>

        <ColunmsItem>
          <Formik
            style={{ flex: 1, border: '1px solid green' }}
            onSubmit={handleSubmitAdminRols}
            initialValues={adminRolesInitilaVlaues}
            validationSchema={adminRolesValidationSchema}>
            {({ isSubmitting, values }) => (
              <Form>
                <FormSection>{t(`settings.admins.profile.permissions`)}</FormSection>
                <Box display="flex" flexDirection="column" gap={4}>
                  <Select
                    name="role"
                    disabled={user?.role !== Role.support}
                    label={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                    options={[
                      {
                        value: Role.admin,
                        label: (
                          <Box display="flex" alignItems="center" gap={1}>
                            <FontAwesomeIcon
                              icon={byPrefixAndName.fak['light-earth-americas-user'] as IconProp}></FontAwesomeIcon>
                            <Typography>{t(`settings.admins.profile.admin`)}</Typography>
                          </Box>
                        ),
                      },
                      {
                        value: Role.support,
                        label: (
                          <Box display="flex" alignItems="center" gap={1}>
                            <FontAwesomeIcon
                              icon={byPrefixAndName.fak['light-screwdriver-wrench-user'] as IconProp}></FontAwesomeIcon>
                            <Typography>{t(`settings.admins.profile.supportAdmin`)}</Typography>
                          </Box>
                        ),
                      },
                    ]}
                  />

                  <Alert severity="info">
                    <Typography fontWeight={theme.typography.fontWeightMedium} paddingBottom={1}>
                      {t(`settings.admins.profile.permissionDescriptionTitle`)}
                    </Typography>
                    <Typography>{t(`settings.admins.profile.${values.role}Description`)}</Typography>
                  </Alert>

                  <Box display="flex" flexDirection="column" gap={0}>
                    <Box>
                      <Checkbox
                        disabled={user?.role !== Role.support}
                        name="receive_campaign_reports"
                        label={t('settings.admins.profile.performanceReportSubscriber')}
                      />
                      <InfoTooltip title={t('settings.admins.profile.performanceReportSubscriberTooltip')} />
                    </Box>
                    <Box>
                      <Checkbox
                        disabled={user?.role !== Role.support}
                        name="receive_plugin_reports"
                        label={t('settings.admins.profile.cywarenessReportPluginNotifications')}
                      />
                      <InfoTooltip title={t('settings.admins.profile.cywarenessReportPluginNotificationsTooltip')} />
                    </Box>
                  </Box>

                  <FormButtons>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      variant="contained"
                      disabled={user?.role !== Role.support}>
                      {t(`settings.admins.profile.saveChanges`)}
                    </LoadingButton>
                  </FormButtons>
                </Box>
              </Form>
            )}
          </Formik>
        </ColunmsItem>
      </TwoColunmsLayout>

      <AdminStatusPopup
        open={disableWarningModalOpen}
        onClose={() => setDisableWarningModalOpen(false)}
        onConfirm={() => setDisableWarningModalOpen(false)}
        admin={adminData}
      />
      <EmailConfirmModal
        title={t('settings.admins.emailConfirmModal.title')}
        open={emailConfirmModalOpen}
        onClose={() => setEmailConfirmModalOpen(false)}
        onConfirm={handleEmailChangeConfirm}
        state={emailConfirmModalState}
      />
    </>
  )
}

export default AdminProfileDetailsSection
