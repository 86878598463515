import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Link, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { useClientDetails } from '@/api/client/client'
import { useUpdateClient } from '@/api/client/update-client'
import { useOrganizationIndustries } from '@/api/organizations/getIndustries'
import { ColunmsItem, FormButtons, FormSection, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import CountrySelect from '@/common/components/Inputs/CountrySelect'
import Select from '@/common/components/Inputs/Select'
import TextField from '@/common/components/Inputs/TextFieldV2'
import LayoutAccordion from '@/common/components/Layout/LayoutPageContent/LayoutAccordion'
import UploadImageBox from '@/common/components/UploadImageBox/UploadImageBox'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { StyledStepIconCircle } from './Styled'

type StepParams = {
  isDone?: boolean
  nextStep: () => void
  open: boolean
  toggleOpen: () => void
}

const OrganizationDetailsStep: FC<StepParams> = ({ isDone, nextStep, open, toggleOpen }) => {
  const { t } = useTranslation()

  const { errorToast, successToast } = useToast()

  const [updateClient] = useUpdateClient()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Organization name is required'),
    origin_country: Yup.string(),
    industry: Yup.string().required('Indusrty is required'),
  })

  const { data: clientDetails } = useClientDetails()
  const { data: queryData } = useOrganizationIndustries()
  const { results: industriesData } = queryData || { results: [] }

  const initialValues = {
    name: clientDetails?.name || '',
    origin_country: clientDetails?.origin_country || '',
    industry: clientDetails?.industry || '',
    logo: clientDetails?.logo,
  }

  const industries = useMemo(
    () => industriesData?.map((i) => ({ value: i.slug, label: t(`industries.${i.slug}`) })),
    [industriesData, t]
  )

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await updateClient({ ...values })
      successToast(t('settings.configurationTab.organizationSettings.organizationUpdated'))
    } catch (e) {
      errorToast('settings.configurationTab.organizationSettings.organizationUpdateFailed')
    }
  }

  return (
    <LayoutAccordion
      title={t('onboarding.step1.title')}
      summaryBackGroundColor={isDone ? theme.palette.green[100] : ''}
      expanded={open}
      onClick={toggleOpen}
      startIcon={
        <StyledStepIconCircle done={isDone}>{isDone ? <FontAwesomeIcon icon={faCheck} /> : 1}</StyledStepIconCircle>
      }>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount>
        {({ isSubmitting, setFieldValue, values, isValid }) => {
          return (
            <Form>
              <TwoColunmsLayout>
                <ColunmsItem fullWidth>
                  <FormSection>{t('onboarding.step1.desctiprionTitle')}</FormSection>
                  <Typography>
                    <Trans
                      i18nKey="onboarding.step1.descriptionLine1"
                      components={{
                        1: <Link href="/settings/configurations" color={theme.palette.blue[800]} target="_blank" />,
                      }}
                    />
                  </Typography>
                  <Typography>{t('onboarding.step1.descriptionLine2')}</Typography>
                </ColunmsItem>
                <ColunmsItem>
                  <Box display="flex" flexDirection="column" gap={4}>
                    <TextField name="name" required label={t('onboarding.step1.companyName')} />
                    <CountrySelect name="origin_country" label={t('onboarding.step1.originCountry')} />
                    <Select name="industry" label={t('onboarding.step1.industry')} options={industries} requiredLabel />
                  </Box>
                </ColunmsItem>
                <ColunmsItem>
                  <UploadImageBox
                    image={values.logo}
                    handleOnFinishImageUpload={(logoUrl) => {
                      setFieldValue('logo', logoUrl)
                    }}
                  />
                </ColunmsItem>
                <ColunmsItem offsetLeft>
                  <FormButtons>
                    <LoadingButton
                      size="large"
                      type="submit"
                      loading={isSubmitting}
                      variant="outlined"
                      disabled={!isValid}>
                      {t('onboarding.step1.submit')}
                    </LoadingButton>
                    <Button size="large" variant="contained" onClick={nextStep}>
                      {t('onboarding.step1.continue')}
                    </Button>
                  </FormButtons>
                </ColunmsItem>
              </TwoColunmsLayout>
            </Form>
          )
        }}
      </Formik>
    </LayoutAccordion>
  )
}

export default OrganizationDetailsStep
