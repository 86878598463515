import settingsPageImage from '@/assets/images/SettingsPage/SettingsPageImage.svg'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Switch, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore

import { ClientDetailsResult } from '@/api/client/client'
import { useUpdateClient } from '@/api/client/update-client'
import { ColunmsItem, TwoColunmsLayout } from '@/common/components/Form/TwoColumnsLayout'
import useToast from '@/common/hooks/useToast'
import styled from '@emotion/styled'
import { FieldWrapper, SubTitle } from './ConfigurationsStyledComponents'
import OrganizationCapacityBar from './OrganizationCapacityBar'
import OrganizationSettings from './OrganizationSettings'
import RedeemPromoCode from './RedeemPromoCode'

export type ConfigurationsProps = {
  organiztion?: ClientDetailsResult
}

const ConfigurationsTab: FC<ConfigurationsProps> = ({ organiztion }) => {
  const { t } = useTranslation()
  const [updateClient] = useUpdateClient()
  const [allowUnverifiedDomains, setAllowUnverifiedDomains] = useState(organiztion?.allow_unverified_domains)
  const { successToast, errorToast } = useToast()

  const handleUnverifiedDomainsChange = async () => {
    try {
      await updateClient({ allow_unverified_domains: !allowUnverifiedDomains })
      setAllowUnverifiedDomains((e) => !e)
      successToast(t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomainChanged'))
    } catch (e) {
      errorToast(t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomainChangeFailed'))
    }
  }
  return (
    <Box>
      <TwoColunmsLayout>
        <ColunmsItem>
          <Box display="flex" flexDirection="column" gap={6}>
            <OrganizationSettings
              name={organiztion?.name}
              country={organiztion?.origin_country}
              logo={organiztion?.logo}
              industry={organiztion?.industry}
            />
            <RedeemPromoCode />
          </Box>
        </ColunmsItem>
        <ColunmsItem>
          <ImageColumn />
        </ColunmsItem>

        <ColunmsItem fullWidth>
          <OrganizationCapacityBar organiztion={organiztion}></OrganizationCapacityBar>
          <FieldWrapper>
            <SubTitle> {t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomains')}</SubTitle>
            <Switch
              className="locked"
              disabled
              checked={allowUnverifiedDomains}
              onChange={handleUnverifiedDomainsChange}
            />
            <Tooltip title={t('settings.configurationTab.unverifiedDomain.tooltip')} placement="top">
              <FontAwesomeIcon icon={faCircleInfo as IconProp} color="#979797" fontSize={20} />
            </Tooltip>
          </FieldWrapper>
        </ColunmsItem>
      </TwoColunmsLayout>
    </Box>
  )
}

const ImageColumn = styled(Box)`
  height: 100%;
  background: no-repeat center url(${settingsPageImage});
  background-size: contain;
`

export default ConfigurationsTab
