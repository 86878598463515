import { Tabs, styled } from '@mui/material'

import { theme } from '@/theme/theme'

const MainTabs = styled(Tabs)(() => ({
  position: 'relative',
  background: 'transparent',
  padding: '0px',
  margin: '0px',
  borderRadius: '0px',
  border: 'none',
  '.MuiTabs-scroller': {
    background: 'transparent',
    padding: '0px',
  },
  '.MuiTabScrollButton-root': {
    padding: 0,
    marginTop: '2px',
    color: theme.palette.blueGray[900],
    width: 32,
  },

  '.MuiTabScrollButton-root.Mui-disabled': {
    padding: 0,
    margin: 0,
    width: 0,
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'transparent',
    top: '0px',
    zIndex: '-1',
  },

  '.MuiTab-root:first-of-type': {
    borderLeft: 'none',
    borderRadius: '15px 0px 0px 0px',
  },
  '.MuiTab-root:last-of-type': {
    borderRight: 'none',
    borderRadius: '0px 15px 0px 0px',
  },
}))

export default MainTabs
