import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { AddMembersToGroupRequest } from '@/types/groups'
import { API_URL } from '../constants'

const addMembersToGroup = async (params: AddMembersToGroupRequest) => {
  const url = `${API_URL}/groups/${params._id}/users/`
  const { data } = await axios.post(url, params, { withCredentials: true })
  return data
}

export const useAddMembersToGroup = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addMembersToGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['groups'],
      })
      await queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })
}
